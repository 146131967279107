import Vue from "vue";

const HOST_API_ORDERS = "/api/v1/orders";

const createOrder = (
  userForPay,
  products: any[],
  addressSelected,
  summary,
  card,
  typeSend,
  shipment,
  shippmentDate: string,
  typeAddress,
  sapData,
  sapErrors: string[]
) => {
  const newOder = {
    user: userForPay,
    products: products.map((p) => {
      return {
        sku: p.NoArticulo,
        description: p.Descripcion,
        stock: p.CantidadStock,
        group: p.Grupo,
        manufacturer: p.Fabricante || "",
        measure: p.UnidadMedida,
        price: p.Precio,
        images: p.Fotos,
        long_description: p.DescripcionLarga,
        downloadable: p.Descargables,
        discount_rate: p.PorcentajeDescuento,
        type: p.Categoria,
        product_category: p.Categoria,
        subcategory: p.SubCategoria,
        ecomerce_category: p.CategoriaEcommerce,
        measures: {
          height: p.Ancho,
          width: p.Altura,
          length: p.Longitud,
          volume: p.Volumen,
          weight: p.Peso,
        },
        quantity: p.quantity,
      };
    }),
    address: addressSelected,
    label_id: shipment.labelId,
    pdf: shipment.labelPDF,
    delivery_type: typeAddress == 0 ? "PICKUP" : "HOME_DELIVERY",
    carrier: typeSend != "" ? typeSend : "UNICORN",
    card_number: card,
    delivery_date: shippmentDate.substring(0, 19),
    subtotal: summary.subtotal,
    taxes: summary.taxes,
    shipping_cost: summary.shipping,
    discount_amount: summary.discount,
    total:
      summary.subtotal + summary.taxes + summary.shipping - summary.discount,
    comments: "NA",
    sap_data: sapData,
    sapErrors,
  };
  return new Promise((resolve, reject) => {
    Vue.axios
      .post(`${HOST_API_ORDERS}`, newOder)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getOrders = (pageSize, pageNum) => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .post(`${HOST_API_ORDERS}/filter?pageSize=${pageSize}&pageNum=${pageNum}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export { createOrder, getOrders };
