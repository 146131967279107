const defaultFilters = () => {
  return {
    Descripcion: null,
    NoArticulo: null,
    Articulo: null,
    CategoriaEcommerce: null,
    Familia: [],
    Categoria: [],
    SubCategoria: [],
    Fabricante: [],
    PrecioMin: 0,
    PrecioMax: 0,
    NumeroPagina: 1,
    RegistrosPorPagina: 12,
    totalRegisters: 0,
  };
};

const state = () => ({
  ...defaultFilters(),
  titleCategory: null,
  totalPages: 1,
});

const actions = {
  setDescription({ commit }, description) {
    commit("setDescription", description);
  },
  setCategories({ commit }, category) {
    commit("setCategories", category);
  },
  setCategoriesFilter({ commit }, category) {
    commit("setCategoriesFilter", category);
  },
  setSubcategories({ commit }, subcategories) {
    commit("setSubcategories", subcategories);
  },
  setBrands({ commit }, brands) {
    commit("setBrands", brands);
  },
  setPageNum({ commit }, page) {
    commit("setPageNum", page);
  },
  setRowPage({ commit }, row) {
    commit("setRowPage", row);
  },
  setTotalRegisters({ commit }, total) {
    commit("setTotalRegisters", total);
  },
  cleanFilters({ commit }) {
    commit("cleanFilters");
  },
  searchBarClean({ commit }) {
    commit("searchBarClean");
  },
};

const mutations = {
  setDescription(state, description) {
    state.Descripcion = description;
  },
  setCategoryForFamily(state, categories) {
    state.Familia = categories.value;
    state.titleCategory = categories.title;
  },
  setCategories(state, categories) {
    state.CategoriaEcommerce = categories.value;
    state.titleCategory = categories.title;
  },
  setCategoriesFilter(state, categories) {
    state.Categoria = categories;
  },
  setSubcategories(state, subcategories) {
    state.SubCategoria = subcategories;
  },
  setBrands(state, brands) {
    state.Fabricante = brands;
  },
  setPageNum(state, page) {
    state.NumeroPagina = page;
  },
  setRowPage(state, rows) {
    state.RegistrosPorPagina = rows;
  },
  setTotalRegisters(state, total) {
    state.totalRegisters = total;
    state.totalPages = Math.ceil(
      state.totalRegisters / state.RegistrosPorPagina
    );
  },
  cleanFilters(state) {
    Object.assign(state, defaultFilters());
    state.titleCategory = null;
  },
  searchBarClean(state) {
    state.titleCategory = null;
    state.totalPages = 1;
    state.CategoriaEcommerce = null;
    state.Categoria = [];
    state.SubCategoria = [];
    state.Fabricante = [];
    state.Familia = [];
    state.NumeroPagina = 1;
    state.RegistrosPorPagina = 12;
    state.totalRegisters = 0;
  },
};

const getters = {
  getPagination(state) {
    return Math.ceil(state.totalRegisters / state.RegistrosPorPagina);
  },
};

export default {
  namespace: true,
  state,
  actions,
  mutations,
  getters,
};
