











































































































































































import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { mapState } from "vuex";
import AddressItem from "@/components/Profile/AddressItem.vue";
import NewAddress from "@/components/Profile/NewAddress.vue";
import SummaryShoppingCard from "@/components/ShoppingCart/SummaryShoppingCard.vue";
import { validateStock } from "@/services/productServices";
import ChangeStockCard from "@/components/ShoppingCart/ChangeStockCard.vue";
import SendByForm from "@/components/ShoppingCart/SendByForm.vue";
import { validations } from "@/helpers/constants";
import { putShoppingCart } from "@/services/shoppingCarServices";

@Component({
  computed: {
    ...mapState(["shoppingCart", "address", "user"]),
  },
  components: {
    AddressItem,
    SummaryShoppingCard,
    NewAddress,
    ChangeStockCard,
    SendByForm,
  },
})
export default class AddressShoppingCart extends Vue {
  @Ref("refShoppingAddress") readonly shoppingAddressForm!: VForm;
  @Ref("refShoppingAddressEmail") readonly refShoppingAddressEmail!: VForm;
  @Prop({ default: false }) disabledIcon!: boolean;
  @Prop({ default: false }) onlyAddress!: boolean;
  fields = validations;
  loadingSaveAddress = false;
  modalChangeStock = false;
  productWithChangeStock: any[] = [];
  changeShipment() {
    this.$store.state.shoppingCart.summary.shipping = 0;
    this.$store.state.shoppingCart.deliverType = "";
  }
  saveAddress() {
    this.loadingSaveAddress = true;
    const validationAddress = this.refShoppingAddressEmail
      ? this.refShoppingAddressEmail.validate()
      : true;
    if (validationAddress) {
      validateStock(this.$store.state.shoppingCart.products)
        .then((res: any) => {
          if (res.length > 0) {
            this.modalChangeStock = true;
            this.productWithChangeStock = res;
          } else {
            this.$store.commit("changeShoppingStatus", 2);
          }
          this.loadingSaveAddress = false;
        })
        .catch((err) => {
          this.loadingSaveAddress = false;
          console.log(err);
        });
    } else {
      this.loadingSaveAddress = false;
    }
  }
  closeModalChangeStock() {
    this.modalChangeStock = false;
    const productsWithoutStock = this.productWithChangeStock.filter((p => p.CantidadStock == 0));
    const productWithChangeStock = this.productWithChangeStock.filter((p => p.CantidadStock > 0));
    productsWithoutStock.forEach(pwos => {
        this.$store.commit("removeProductToCart", pwos);
    });
    productWithChangeStock.forEach((pws) => {
      this.$store.commit("updateProductToCart", pws);
    })
    this.$nextTick(() => {
      putShoppingCart(this.$store.state.shoppingCart.products);
      if (this.$store.state.shoppingCart.products.length == 0) {
        this.$store.commit("cleanShoppingCart");
        this.$store.commit("cleanPaidMethodForm");
        this.$store.commit("cleanAddressForm");
      }
      this.$store.commit("cleanShipingCost");
    });
  }
}
