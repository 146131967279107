

















































import { Component, Vue, Prop } from "vue-property-decorator";
import ProductList from "@/components/Product/ProductList.vue";
import ProductFilter from "@/components/Product/ProductFilter.vue";
import { getFilterProduct } from "@/services/productServices";
import { mapState } from "vuex";

@Component({
  computed: {
    ...mapState(["filter"]),
  },
  components: {
    ProductList,
    ProductFilter,
  },
})
export default class ProductsCategory extends Vue {
  categoryTitle = "";
  sheetFilters = false;
  mounted() {
    getFilterProduct(this.$store.state.filter).then((res: any) => {
      if (res.EsValido) {
        this.$store.commit("setProductList", res.Productos.Productos);
        this.$store.commit("setTotalRegisters", res.Productos.TotalRegistros);
      }
    });
  }
  beforeDestroy() {
    this.$store.commit("cleanFilters");
  }
}
