














import { Component, Vue } from "vue-property-decorator";
import BuyedListItem from "@/components/Profile/BuyedListItem.vue";
import SimpleLoader from "@/components/Alerts/SimpleLoader.vue";

@Component({
  components: {
    BuyedListItem,
    SimpleLoader,
  },
})
export default class MyList extends Vue {
  loadingMyList = false;
  mounted() {
    this.loadingMyList = true;
    this.loadingMyList = false;
  }
}
