











































































































































































































import { mapState } from "vuex";
import { Component, Vue } from "vue-property-decorator";
import OrderItemProduct from "@/components/Profile/OrderItemProduct.vue";
import moment from "moment";

@Component({
  components: {
    OrderItemProduct,
  },
  computed: {
    ...mapState(["order"]),
  },
})
export default class OrderDetails extends Vue {
  goOrderList() {
    this.$store.commit("setShowOrder", false);
  }

  formatDate(date) {
    return moment(date).locale("es").format("DD/MMMM/YYYY");
  }

  renderDeliveryDateTitle(carrier, deliveryType) {
    if (carrier === "UNICORN" && deliveryType === "PICKUP")
      return "Fecha para recoger en tienda: ";
    else return "Fecha de envío: ";
  }

  renderStatus(status) {
    switch (status) {
      case "DONE":
        return "Compra completada";
      case "CANCELLED":
        return "Compra cancelada";
      default:
        break;
    }
  }

  renderCarrier(carrier, deliveryType) {
    if (carrier === "UNICORN" && deliveryType === "HOME_DELIVERY")
      return "Unicornio 115 - Envío a domicilio";
    else if (carrier === "UNICORN" && deliveryType === "PICKUP")
      return "Unicornio 115 - Recoger en tienda";
    else return "DHL";
  }
}
