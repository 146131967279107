import store from "@/store";

const IMAGE_STORAGE_PATH =
  "https://tiendalinea.blob.core.windows.net/fotos-sku";
const DOC_STORAGE_PATH =
  "https://tiendalinea.blob.core.windows.net/descargables";

const getProductDescription = (description, maxLength) => {
  if (description.length > maxLength)
    return `${description.substring(0, maxLength)}...`;
  else return description;
};

const getProductImageUrl = (productNumber) => {
  return `${IMAGE_STORAGE_PATH}/${formatProductNumber(productNumber)}.png`;
};

const getProductDetailedPDFUrl = (productNumber) => {
  return `${DOC_STORAGE_PATH}/${formatProductNumber(productNumber)}.pdf`;
};

const formatProductNumber = (productNumber) => {
  return productNumber?.replace("/", "-");
};

const getNormalPrice = (price, discountPercentage) => {
  return price / ((100 - discountPercentage) / 100);
};

const getInformationProductFormat = (information: string) => {
  const infoFormated: string = information
    .replace(/\|\s/g, "</strong><br>")
    .replace(/\:\s/g, ": </strong>")
    .replace(/\|/g, "<strong><br>")
    .replace(/\•/g, "<li>");
  return infoFormated;
};

export {
  getProductDescription,
  getProductImageUrl,
  getProductDetailedPDFUrl,
  getNormalPrice,
  formatProductNumber,
  getInformationProductFormat,
};
