





































import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { getAddress } from "@/services/addressService";
import AddressItem from "@/components/Profile/AddressItem.vue";
import SimpleLoader from "@/components/Alerts/SimpleLoader.vue";
import { messagesAlert } from "@/plugins/toastr";

@Component({
  computed: {
    ...mapState(["address"]),
  },
  components: {
    AddressItem,
    SimpleLoader,
  },
})
export default class MyAddress extends Vue {
  loadingMyAddress = false;

  mounted() {
    this.loadingMyAddress = true;
    getAddress(20, 1)
      .then((res: any) => {
        this.$store.commit("setAddress", res.data);
      })
      .catch((err) => {
        messagesAlert(
          "error",
          "¡Error!",
          "Ha habido un error al momento de cargar sus direcciones"
        );
      })
      .finally(() => {
        this.loadingMyAddress = false;
      });
  }
  beforeDestroy() {
    this.$store.commit("setShowAddressForm", false);
    this.$store.commit("cleanAddressForm");
  }
  addNewAddress() {
    this.$store.commit("setShowAddressForm", true);
  }
}
