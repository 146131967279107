




































































































































































import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { mapState } from "vuex";
import SummaryShoppingCard from "@/components/ShoppingCart/SummaryShoppingCard.vue";
import SimpleLoader from "@/components/Alerts/SimpleLoader.vue";
import CouponForm from "@/components/ShoppingCart/CouponForm.vue";

import NewPaid from "@/components/Profile/NewPaid.vue";
import AccountItem from "@/components/Profile/AccountItem.vue";
import {
  generateOrder,
  savePaymentReference,
  cancelOrder,
} from "@/services/sapServices";
import { generateTracking } from "@/services/shipmentServices";
import {
  generateCharges,
  returnCharge,
  getCards,
} from "@/services/openPayServices";
import { createOrder } from "@/services/orderServices";
import { messagesAlert } from "@/plugins/toastr";
import { putShoppingCart, couponRequest } from "@/services/shoppingCarServices";
import SendByForm from "@/components/ShoppingCart/SendByForm.vue";
import moment from "moment-timezone";
import { validations } from "@/helpers/constants";

@Component({
  computed: {
    ...mapState({
      shoppingCart: (state: any) => state.shoppingCart,
      paidMethod: (state: any) => state.paidMethod,
      user: (state: any) => state.user,
      payMethodSelected: (state: any) => state.shoppingCart.payMethodSelected,
    }),
  },
  components: {
    SummaryShoppingCard,
    AccountItem,
    NewPaid,
    SimpleLoader,
    SendByForm,
    CouponForm,
  },
})
export default class PaidMethod extends Vue {
  @Ref("formPaymentPlan") readonly formPaymentPlan!: VForm;
  @Prop({ default: false }) disabledIcon!: boolean;
  fields = validations;
  loadingPayment = false;
  reference = "";
  paymentPlanList = [
    { label: "Una sola exhibición", plan: 1, min: 0 },
    { label: "3 meses sin interéses", plan: 3, min: 300 },
  ];
  get validPaymentPlanList() {
    if (
      ['visa', 'mastercard'].includes(this.$store.state.shoppingCart.payMethodSelected.brand)
    ) {
      const totalToPaid =
        this.$store.state.shoppingCart.summary.subtotal +
        this.$store.state.shoppingCart.summary.taxes +
        this.$store.state.shoppingCart.summary.shipping -
        this.$store.state.shoppingCart.summary.discount;
      return this.paymentPlanList.filter((pp) => totalToPaid >= pp.min);
    } else {
      return this.paymentPlanList.filter((pp) => pp.plan === 1);
    }
  }

  get doPaymentValidation() {
    const paymentSelected =
      this.$store.state.shoppingCart.payMethodSelected == null;
    const paymentPlanSelected =
      this.$store.state.shoppingCart.payMethodSelected?.type == "credit" &&
      this.$store.state.shoppingCart.paymentPlan == null;
    const deliverTypeSelected =
      this.$store.state.shoppingCart.deliverType == "";
    const loading = this.$store.state.shoppingCart.loadingCalculateShippment;
    return (
      paymentSelected || paymentPlanSelected || deliverTypeSelected || loading
    );
  }

  get userForPay() {
    return {
      ...this.$store.state.user.user,
      first_name:
        this.$store.state.user.user.first_name ||
        this.$store.state.shoppingCart.addressSelected.full_name,
      last_name:
        this.$store.state.user.user.last_name ||
        this.$store.state.shoppingCart.addressSelected.full_name,
      client_id: this.$store.state.user.user.client_id,
      email:
        this.$store.state.user.user.email ||
        this.$store.state.shoppingCart.addressSelected.email,
      country:
        this.$store.state.user.user.country ||
        this.$store.state.shoppingCart.addressSelected.country,
      city:
        this.$store.state.user.user.city ||
        this.$store.state.shoppingCart.addressSelected.city,
      zip_code:
        this.$store.state.user.user.zip_code ||
        this.$store.state.shoppingCart.addressSelected.zip_code.toString(),
    };
  }

  get products() {
    return this.$store.state.shoppingCart.products;
  }

  get payMethod() {
    return this.$store.state.shoppingCart.payMethodSelected;
  }

  get totalToPaid() {
    return (
      this.$store.state.shoppingCart.summary.subtotal +
      this.$store.state.shoppingCart.summary.taxes +
      this.$store.state.shoppingCart.summary.shipping -
      this.$store.state.shoppingCart.summary.discount
    ).toFixed(2);
  }
  mounted() {
    if (this.$store.getters.getJWTUser) {
      getCards().catch((err) => {
        this.$store.commit("setPaidMethods", []);
        messagesAlert(
          "error",
          "¡Error!",
          "Ha habido un error al momento de cargar su información"
        );
      });
    }
    if (this.$route.query?.id) {
      this.finalizationBuy();
      this.loadingPayment = true;
    }
  }
  updatePayMethodSelected(payMethod) {
    this.$store.commit("addPayMethodToCart", payMethod);
  }
  restoreForm() {
    this.$store.commit("setPaidMethods", []);
    this.$store.commit("showNewForm", true);
    this.$store.commit("cleanPaidMethodForm");
    this.$store.state.shoppingCart.payMethodSelected = null;
  }
  payShoppingCart() {
    if (this.formPaymentPlan && !this.formPaymentPlan.validate()) {
      messagesAlert(
        "error",
        "¡Error!",
        "No ha seleccionado ningún plan de pagos"
      );
      return;
    }
    this.loadingPayment = true;

    generateOrder(
      this.userForPay,
      this.products,
      this.payMethod.card_number.substr(-4),
      this.totalToPaid
    )
      .then((resOrder: any) => {
        if (!resOrder.EsValido) {
          this.$store.commit("addSapError", resOrder.Mensaje);
        } else {
          let arrayMessage = resOrder?.Mensaje.split(" ");
          this.$store.commit(
            "setReference",
            arrayMessage ? arrayMessage.pop() : ""
          );
        }
        generateCharges(
          this.userForPay,
          this.payMethod.id,
          this.totalToPaid,
          this.$store.state.shoppingCart.paymentPlan
        )
          .then((resCharge: any) => {
            window.open(resCharge.payment_method.url, "_self");
          })
          .catch(() => {
            this.errorAlertMessage(this.$store.state.shoppingCart.reference);
          });
      })
      .catch(() => {
        this.errorAlertMessage("");
      });
  }

  finalizationBuy() {
    const addressSelected = this.$store.state.shoppingCart.addressSelected;
    const typeSend = this.$store.state.shoppingCart.deliverType;
    const shippmentDate =
      moment()
        .tz("America/Cancun")
        .add(
          moment().tz("America/Cancun").hour() >= 12 && typeSend == "DHL"
            ? 1
            : 0,
          "day"
        )
        .format("YYYY-MM-DDT") + "16:00:00GMT-05:00";
    let newPayment = {
      NoCliente: this.userForPay.client_id,
      Nombre: `${this.userForPay.first_name} ${this.userForPay.last_name}`,
      MontoPago: this.totalToPaid,
      Referencia: this.$store.state.shoppingCart.reference,
      ConceptoPago: "Compra E-commerce",
      Cuenta: process.env.VUE_APP_ACCOUNT_NUMBER,
    };
    savePaymentReference(newPayment)
      .then((resPayment: any) => {
        var referencePayment = "";
        if (!resPayment.EsValido) {
          this.$store.commit("addSapError", resPayment.Mensaje);
        } else {
          let arrayMessagePayment = resPayment?.Mensaje.split(" ");
          referencePayment = arrayMessagePayment
            ? arrayMessagePayment.pop()
            : "";
        }
        generateTracking(
          addressSelected,
          this.products,
          shippmentDate,
          this.$store.state.shoppingCart.typeAddress,
          typeSend,
          this.userForPay.email
        )
          .then((resTracking: any) => {
            addressSelected.phone_number =
              addressSelected.phone_number.toString();
            let sapData = {
              order_invoice: this.$store.state.shoppingCart.reference,
              payment_invoice: referencePayment,
            };
            createOrder(
              this.userForPay,
              this.products,
              addressSelected,
              this.$store.state.shoppingCart.summary,
              this.payMethod.card_number,
              typeSend,
              resTracking.data,
              shippmentDate,
              this.$store.state.shoppingCart.typeAddress,
              sapData,
              this.$store.getters.getSAPErrors
            )
              .then(() => {
                this.successPayment();
                const coupon = this.$store.state.shoppingCart.couponCode;
                if (coupon != null) {
                  couponRequest(coupon.code, true)
                    .then(() => {
                      console.log("Success coupon request");
                    })
                    .catch(() => {
                      console.log("Error coupon request");
                    });
                }
              })
              .catch((err) => {
                console.log("Error create order", err);
                if (this.$route.query.id) {
                  returnCharge(this.$route.query.id, this.totalToPaid);
                }
                this.errorAlertMessage(
                  this.$store.state.shoppingCart.reference
                );
              });
          })
          .catch((err) => {
            console.log("Error generate tracking");
            if (!this.$route.query.id) {
              returnCharge(this.$route.query.id, this.totalToPaid);
            }
            this.errorAlertMessage(this.$store.state.shoppingCart.reference);
          });
      })
      .catch(() => {
        this.errorAlertMessage(this.$store.state.shoppingCart.reference);
      });
  }
  successPayment() {
    messagesAlert(
      "success",
      "¡Éxito!",
      "Compra realizada correctamente",
      () => {
        this.$store.state.shoppingCart.addressSelected.email = "";
        this.$store.commit("cleanShoppingCart");
        this.$store.commit("cleanProductShoppingCart");
        this.$store.commit("cleanAddressForm");
        this.$store.commit("cleanPaidMethodForm");
        this.$store.commit("setCouponCode", null);
        this.loadingPayment = false;
        if (this.$store.state.user.jwt) {
          this.$router.push({ name: "profile", params: { tab: "1" } });
          putShoppingCart(this.$store.state.shoppingCart.products);
        } else {
          this.$router.push({ path: "/home" });
        }
      }
    );
  }
  errorAlertMessage(docNum: any) {
    this.loadingPayment = false;
    messagesAlert(
      "error",
      "¡Error!",
      "Ha habido un error al momento de realizar el pago, intente más tarde"
    );

    if (docNum != "") {
      cancelOrder(docNum)
        .catch((err) => console.log(err))
        .finally(() => {
          this.$store.state.shoppingCart.reference = "";
        });
    }
  }
}
