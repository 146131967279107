<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { metaInfo } from "@/helpers/constants";
import { getSystemSettings } from "@/services/firebaseService";

export default {
  name: "App",
  metaInfo: metaInfo,

  mounted() {
    getSystemSettings().then((data) => {
      this.$store.commit("setSystemSettings", data);
      if (data.isMaintenanceMode) {
        return this.$router.replace("maintenance");
      } else {
        OpenPay.setId(process.env.VUE_APP_OPEN_PAY_ID);
        OpenPay.setApiKey(process.env.VUE_APP_OPEN_PAY_PUBLIC_KEY);
        OpenPay.setSandboxMode(true);
        this.$store.commit("setDeviceSessionToken", OpenPay.deviceData.setup());
      }
    });
  },

  destroyed() {
    this.$store.commit("cleanDevieceSessionToken", OpenPay.deviceData.setup());
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
