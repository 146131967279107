
























































































import moment from "moment";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Footer extends Vue {
  renderYear() {
    return moment().format("YYYY");
  }
}
