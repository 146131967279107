






































import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import ShoppingProductCard from "@/components/ShoppingCart/ShoppingProductCard.vue";

@Component({
  computed: {
    ...mapState(["shoppingCart"]),
  },
  components: {
    ShoppingProductCard,
  },
})
export default class MyProductsShoppingCart extends Vue {
  changeAddress() {
    this.$store.state.shoppingCart.summary.shipping = 0;
    if (this.$store.state.shoppingCart.typeAddress == 0) {
      this.$store.state.shoppingCart.deliverType = "UNICORN";
    } else {
      this.$store.state.shoppingCart.deliverType = "";
    }

    this.$store.commit("cleanAddressForm");
    if (this.$store.state.shoppingCart.typeAddress === 0) {
      this.$store.commit(
        "addAddressToCart",
        this.$store.getters.getUnicornAddress
      );
    } else {
      this.$store.commit(
        "addAddressToCart",
        this.$store.getters.getDefaultAddress
      );
    }
  }
}
