





















































import { Component, Vue } from "vue-property-decorator";
import CarouselProducts from "@/components/Carousel/CarouselProducts.vue";
import CarouselPromotions from "@/components/Carousel/CarouselPromotions.vue";
import Section from "@/components/Sections.vue";
import ProductCategoryCard from "@/components/Product/ProductCategoryCard.vue";
import {
  getProductSections,
  getProductFamilies,
} from "@/services/productServices";

@Component({
  components: {
    CarouselProducts,
    CarouselPromotions,
    ProductCategoryCard,
    Section,
  },
})
export default class HomeView extends Vue {
  isLoadingProductFamilies = false;
  discountProducts: any[] = [];
  mostSolds: any[] = [];
  productSections: any[] = [];
  categories: ProductCategory[] = [];
  recomended: any[] = [];
  mounted() {
    this.isLoadingProductFamilies = true;
    getProductFamilies(5, 1)
      .then((res: any) => {
        this.categories = res.data;
      })
      .finally(() => {
        this.isLoadingProductFamilies = false;
      });

    getProductSections()
      .then((res: any) => {
        this.productSections = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
