
























































import { mapState } from "vuex";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  computed: {
    ...mapState(["filter"]),
  },
})
export default class CategoriesCardFilter extends Vue {
  @Prop() title!: string;
  @Prop() values!: UnicornRequest;
  @Prop() type!: string;
  @Prop() viewMore!: Function;
  @Prop({ default: 1 }) typeInput!: number; // 1- For checkbox 2.- For radio buttom
  valuesSelected = [];
}
