const state = () => ({
  productList: [],
  product: {},
});

const actions = {
  setProductList({ commit }, products) {
    commit("setProductList", products);
  },
  clearProductList({ commit }) {
    commit("clearProductList");
  },
  setProduct({ commit }, product) {
    commit("setProduct", product);
  },
  clearProduct({ commit }) {
    commit("clearProduct");
  },
};

const mutations = {
  setProductList(state, products) {
    state.productList = products;
  },
  clearProductList(state) {
    state.productList = [];
  },
  setProduct(state, product) {
    state.product = product;
  },
  clearProduct(state) {
    state.product = {};
  },
};

export default {
  namespace: true,
  state,
  actions,
  mutations,
};
