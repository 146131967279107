





















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class SimpleLoader extends Vue {
  @Prop({ default: "Cargando..." }) message!: string;
  @Prop() showLoader!: boolean;
}
