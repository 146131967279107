






































































































































import { Component, Vue } from "vue-property-decorator";
import CarouselPromotions from "@/components/Carousel/CarouselPromotions.vue";

@Component({
  components: {
    CarouselPromotions,
  },
})
export default class AboutUsView extends Vue {}
