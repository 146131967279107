















import { Component, Vue, Ref } from "vue-property-decorator";
import { getBanners } from "@/services/firebaseService";

@Component
export default class CarouselPromotion extends Vue {
  @Ref("swiper-promotions") swiperRoot!: HTMLDivElement;
  promImages: PromImage[] = [];
  promImagesModel = null;
  mounted() {
    getBanners().then((res: any) => {
      this.promImages = res;
    });
  }

  getImageUrl(prom: PromImage) {
    if (this.$vuetify.breakpoint.xl) return prom.imageLargeScreenUrl;
    if (this.$vuetify.breakpoint.lg) return prom.imageDesktopUrl;
    if (this.$vuetify.breakpoint.md) return prom.imageTabletUrl;
    if (this.$vuetify.breakpoint.smAndDown) return prom.imageMobileUrl;
    return prom.imageDesktopUrl;
  }

  goToProducts(prom: PromImage) {
    if (prom.ecommerceCategory && prom.ecommerceCategory != "") {
      this.$store.commit("setCategories", {
        value: prom.ecommerceCategory,
        title: prom.title,
      });
      this.$router.push({
        name: "productListCategory",
      });
    }
  }
}
