






import { validations } from "@/helpers/constants";
import { Component, Vue } from "vue-property-decorator";
import NewPasswordForm from "@/components/Profile/NewPasswordForm.vue";
import { changePassword } from "@/services/accountServices";
import { messagesAlert } from "@/plugins/toastr";

@Component({
  components: {
    NewPasswordForm,
  },
})
export default class ChangePassword extends Vue {
  validations = validations;
  changePassword(newPassord) {
    changePassword(newPassord.newPassword, this.$route.query.token)
      .then((res) => {
        messagesAlert(
          "success",
          "¡Exito!",
          "La contraseña se ha actualizado correctamente",
          () => this.$router.push({ path: "/login" })
        );
      })
      .catch(() => {
        messagesAlert(
          "error",
          "¡Error!",
          "Ha habido un error al momento de cargar su usuario"
        );
      });
  }
}
