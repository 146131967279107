









































import { Component, Vue } from "vue-property-decorator";
import ProductCard from "@/components/Product/ProductCard.vue";
import SimpleLoader from "@/components/Alerts/SimpleLoader.vue";
import { mapState } from "vuex";
import { getFilterProduct } from "@/services/productServices";

@Component({
  components: {
    ProductCard,
    SimpleLoader,
  },
  computed: {
    ...mapState(["products", "filter"]),
  },
})
export default class ProductList extends Vue {
  loadingMoreProducts = false;

  getProducts() {
    return getFilterProduct(this.$store.state.filter).then((res: any) => {
      if (res.EsValido) {
        this.$store.commit("setProductList", res.Productos.Productos);
        this.$store.commit("setTotalRegisters", res.Productos.TotalRegistros);
      }
    });
  }
  changePage() {
    this.loadingMoreProducts = true;
    this.getProducts().then(() => {
      this.loadingMoreProducts = false;
      setTimeout(() => window.scrollTo(0, 0), 100);
    });
  }
}
