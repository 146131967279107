



















import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ProductRecomend extends Vue {
  @Prop() recomend!: ProductSuggest;
  goToProductList() {
    this.$store.commit("setCategoriesFilter", [this.recomend.code]);
    this.$nextTick(() => {
      this.$router.push({
        name: "productList",
      });
    });
  }
}
