


















































import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import {
  getProductDescription,
  getProductImageUrl,
  formatProductNumber,
} from "@/helpers/product-utils";

@Component({
  computed: {
    ...mapState(["user"]),
  },
})
export default class ProductPromCard extends Vue {
  @Prop() product!: Product;
  isFav = false;

  getImageSrc(productNumber) {
    return getProductImageUrl(productNumber);
  }

  getProductDescription(description) {
    return getProductDescription(description, 60);
  }

  addWishlist() {
    this.isFav = !this.isFav;
  }
  goToProductView() {
    this.$store.commit("setProduct", this.product);
    this.$nextTick(() => {
      this.$router.push({
        path: `/product/${formatProductNumber(this.product.NoArticulo)}-${
          this.product.Precio
        }`,
      });
    });
  }
}
