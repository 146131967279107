import Vue from "vue";
import VueGtag from "vue-gtag";
import router from "@/router";
import firebaseConfig from "@/config/firebase";

Vue.use(
  VueGtag,
  {
    config: {
      id: firebaseConfig.measurementId,
    },
  },
  router
);
