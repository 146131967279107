











































import { Component, Vue } from "vue-property-decorator";
import Order from "@/components/Profile/Order.vue";
import SimpleLoader from "@/components/Alerts/SimpleLoader.vue";
import { getOrders } from "@/services/orderServices";

@Component({
  components: {
    Order,
    SimpleLoader,
  },
})
export default class MyOrders extends Vue {
  loadingMyOrders = false;
  pagination = {
    page_number: 1,
    page_size: 20,
    page_total: 1,
    total_records: 1,
  };
  orderList = [];
  mounted() {
    this.getPage();
  }
  getPage() {
    this.loadingMyOrders = true;
    getOrders(this.pagination.page_size, this.pagination.page_number)
      .then((res: any) => {
        this.orderList = res.data;
        this.pagination = res.pagination;
        this.loadingMyOrders = false;
      })
      .catch((err) => {
        this.loadingMyOrders = false;
      });
  }
}
