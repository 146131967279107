













































































import { Component, Vue, Prop } from "vue-property-decorator";
import { putShoppingCart } from "@/services/shoppingCarServices";
import { getProductImageUrl } from "@/helpers/product-utils";

@Component
export default class ShoppingProductCard extends Vue {
  @Prop() product!: any;

  getImageSrc(productNumber) {
    return getProductImageUrl(productNumber);
  }

  changeQuantity(type) {
    this.$store.commit("changeQuantityProduct", {
      product: this.product,
      type,
    });
    this.$nextTick(() => {
      putShoppingCart(this.$store.state.shoppingCart.products);
    });
  }

  removeToCart() {
    this.$store.commit("removeProductToCart", this.product);
    this.$nextTick(() => {
      putShoppingCart(this.$store.state.shoppingCart.products);
      if (this.$store.state.shoppingCart.products.length == 0) {
        this.$store.commit("cleanShipingCost");
        this.$store.commit("cleanShoppingCart");
        this.$store.commit("cleanPaidMethodForm");
        this.$store.commit("cleanAddressForm");
      }
    });
  }
}
