import Vue from "vue";
import store from "@/store";

const HOST_API_SHOPPING_CART = "/api/v1/shopping-car";
const HOST_API_VOUCHERS = "/api/v1/vouchers";

const getShoppingCart = async () => {
  const userId = store.getters.getUserId;
  if (userId && typeof userId == "string") {
    return new Promise((resolve, reject) => {
      Vue.axios
        .get(`${HOST_API_SHOPPING_CART}/${userId}`)
        .then((res) => {
          resolve(res);
          setCartForRequest(res.data.products);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

const putShoppingCart = async (cart) => {
  const userId = store.getters.getUserId;
  if (userId && typeof userId == "string") {
    const format_cart = cart.map((c) => {
      return {
        sku: c.NoArticulo,
        description: c.Descripcion,
        stock: c.CantidadStock,
        group: c.Grupo,
        manufacturer: c.Marca || "",
        measure: c.UnidadMedida,
        price: c.Precio,
        images: c.Fotos,
        long_description: c.DescripcionLarga,
        downloadable: c.Descargables,
        discount_rate: c.PorcentajeDescuento,
        type: c.Familia,
        product_category: c.Categoria,
        subcategory: c.SubCategoria,
        ecomerce_category: c.CategoriaEcommerce,
        measures: {
          height: c.Altura,
          width: c.Ancho,
          length: c.Longitud,
          volume: c.Volumen,
          weight: c.Peso,
        },
        quantity: parseInt(c.quantity),
      };
    });
    return new Promise((resolve, reject) => {
      Vue.axios
        .put(`${HOST_API_SHOPPING_CART}/${userId}`, { products: format_cart })
        .then((res) => {
          resolve(res);
          setCartForRequest(res.data.products);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

const setCartForRequest = async (products: any[]) => {
  const format_to_render = products.map((p) => {
    return {
      Altura: p.measures.height,
      Ancho: p.measures.width,
      CantidadStock: p.stock,
      Categoria: p.product_category,
      CategoriaEcommerce: p.ecomerce_category,
      Descargables: p.downloadable,
      Descripcion: p.description,
      DescripcionLarga: p.long_description,
      Fabricante: p.Marca,
      Familia: p.type,
      Fotos: p.images,
      Grupo: p.group,
      Longitud: p.measures.length,
      Marca: p.Marca,
      NoArticulo: p.sku,
      Peso: p.measures.weight,
      PorcentajeDescuento: p.discount_rate,
      Precio: p.price,
      SubCategoria: p.subcategory,
      UnidadMedida: p.measure,
      Volumen: p.measures.volume,
      quantity: p.quantity,
    };
  });
  store.commit("setProductCart", format_to_render);
};

const voucherError = {
  VOUCHER_ALREADY_USED: "Cupón ya canjeado",
  "jwt must be provided": "Sin autorización para canjear este cupón",
  VOUCHER_NOT_FOUND: "Cupón inválido",
  ERROR: "Error al canjear su cupón",
};

const couponRequest = (code: string, apply: boolean) => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .get(`${HOST_API_VOUCHERS}/apply?code=${code}&apply=${apply}`)
      .then((res) => {
        if (res.data.voucher_id) {
          resolve(res);
        } else {
          reject(voucherError[res.data.message]);
        }
      })
      .catch((err) => {
        reject(voucherError[err.data.message]);
      });
  });
};

export { getShoppingCart, putShoppingCart, couponRequest };
