import AuthLayout from "../layouts/Auth.vue";
import Login from "../views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";
import RecoverPass from "@/views/auth/RecoverPass.vue";
import ChangePassword from "@/views/auth/ChangePassword.vue";
import AuthSuccessView from "@/views/auth/AuthSuccessView.vue";
import ActivateAccount from "@/views/auth/ActivateAccount.vue";

export default {
  path: "/auth",
  redirect: "login",
  component: AuthLayout,
  children: [
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        title: "Login",
      },
    },
    {
      path: "/signup",
      name: "register",
      component: Register,
      meta: {
        title: "Registrar",
      },
    },
    {
      path: "/recover_password",
      name: "recover_password",
      component: RecoverPass,
      meta: {
        title: "Recuperar contraseña",
      },
    },
    {
      path: "/change_password",
      name: "change_password",
      component: ChangePassword,
      meta: {
        title: "Recuperar contraseña",
      },
    },
    {
      path: "/register_success",
      name: "register_success",
      component: AuthSuccessView,
      meta: {
        title: "Registro éxitoso",
      },
      props: {
        title: null,
        message: null,
      },
    },
    {
      path: "/activate-account",
      name: "activate-account",
      component: ActivateAccount,
      meta: {
        title: "Activar cuenta",
      },
    },
  ],
};
