const defaultNewCardForm = () => {
  return {
    card_number: null,
    expiration_month: null,
    expiration_year: null,
    cvv2: null,
    holder_name: null,
  };
};

const state = () => ({
  paidMethod: [],
  newCardForm: defaultNewCardForm(),
  showNewForm: false,
});

const actions = {
  setPaidMethods({ commit }, paidMethod) {
    commit("setPaidMethods", paidMethod);
  },
  setCardForm({ commit }, card) {
    commit("setCardForm", card);
  },
  cleanPaidMethodForm({ commit }) {
    commit("cleanPaidMethodForm");
  },
  showNewForm({ commit }, show) {
    commit("showNewForm", show);
  },
};

const getters = {
  getDefaultPaidMethod(state) {
    return state.paidMethod.find((a) => a.default);
  },
};

const mutations = {
  setPaidMethods(state, paidMethod) {
    state.paidMethod = paidMethod;
  },
  setCardForm(state, card) {
    state.newCardForm = card;
  },
  showNewForm(state, show) {
    state.showNewForm = show;
  },
  cleanPaidMethodForm(state) {
    delete state.newCardForm.id;
    Object.assign(state.newCardForm, defaultNewCardForm());
  },
};

export default {
  namespace: true,
  state,
  actions,
  getters,
  mutations,
};
