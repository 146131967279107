import store from "@/store";
import Crypto from "crypto-js";
import { getShoppingCart } from "@/services/shoppingCarServices";

const LOCAL_STORAGE_KEY = "elunicornio115_user";

const initializeLocalStorage = () => {
  if (localStorage.getItem("user") != null) {
    const decrypt = Crypto.AES.decrypt(
      localStorage.getItem("user"),
      LOCAL_STORAGE_KEY
    );
    const user = JSON.parse(decrypt.toString(Crypto.enc.Utf8));
    store.commit("setUser", user);
    getShoppingCart();
  }
};

export { initializeLocalStorage };
