






























































































































import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import OrderItemProduct from "@/components/Profile/OrderItemProduct.vue";
import ProofPayment from "@/components/Document/ProofPayment.vue";
import VueHtml2pdf from "vue-html2pdf";
import moment from "moment";

@Component({
  components: {
    OrderItemProduct,
    ProofPayment,
    VueHtml2pdf,
  },
})
export default class Order extends Vue {
  @Prop({ required: true }) order!: any;
  @Ref("refProofPayment") readonly refProofPayment!: any;
  pdfConfig = {
    html2canvas: {
      useCORS: true,
    },
  };
  EMAIL_UNICORN = "administracion@materialeselunicornio.com.mx";

  goOrderDetails() {
    this.$store.commit("setOrderDetail", this.order);
    this.$store.commit("setShowOrder", true);
  }
  renderStatus(status) {
    switch (status) {
      case "DONE":
        return "Compra completada";
      case "CANCELLED":
        return "Compra cancelada";
      default:
        break;
    }
  }
  formatDate(date) {
    return moment(date).locale("es").format("DD/MMMM/YYYY");
  }
  goToTracker() {
    this.$router.push({
      path: "/tracking",
      query: {
        trackId: this.order.label_id,
      },
    });
  }
  downloadProofPayment() {
    this.refProofPayment.downloadPdf();
  }
  async beforeDownload({ html2pdf, options, pdfContent }) {
    await html2pdf()
      .set(options)
      .from(pdfContent)
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.setTextColor(150);
          pdf.text(
            "Página " + i + " de " + totalPages,
            pdf.internal.pageSize.getWidth() * 0.88,
            pdf.internal.pageSize.getHeight() - 1
          );
        }
      });
  }
  requestBilling(order) {
    const billingData = this.$store.getters.getBillingUser;
    const subject = `Solicitud de factura para mi orden #${order.order_id}`;
    const body = `Hola Unicornio 115, me gustaría solicitar la factura de mi compra #${
      order.order_id
    }%0A%0A
                  RFC: ${billingData?.rfc || "*Ingresa tu rfc*"}
                  %0A%0A
                  Razón social: ${
                    billingData?.business_name || "*Ingresa tu razón social*"
                  }
                  %0A%0A
                  Régimen fiscal: ${
                    billingData?.tax_regime || "*Ingresa tu régimen fiscal*"
                  }
                  %0A%0A
                  Uso cfdi: ${billingData?.cfdi_use || "*Ingresa tu cfdi*"}
                  %0A%0A
                  Email: ${billingData?.email || "*Ingresa tu email*"}
                  %0A%0A
                  Código postal: ${
                    billingData?.zip_code || "*Ingresa tu código postal*"
                  }
                  %0A%0A
                  `;
    window.open(`mailto:${this.EMAIL_UNICORN}?subject=${subject}&body=${body}`);
  }
}
