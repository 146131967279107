




















import Footer from "@/components/Footer.vue";
import Headbar from "@/components/Headbar.vue";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    Footer,
    Headbar,
  },
})
export default class Store extends Vue {
  getPaddingTopForContainer() {
    if (this.$vuetify.breakpoint.mdAndUp) return "";
    else return "pt-16";
  }

  mounted() {
    if (this.$route.path === "/") {
      this.$router.push({ name: "homepage" });
    }
  }
}
