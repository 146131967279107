






























































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { messagesAlert } from "@/plugins/toastr";
import { mapState } from "vuex";
import { putShoppingCart } from "@/services/shoppingCarServices";
import {
  getProductDescription,
  getProductImageUrl,
  getNormalPrice,
  formatProductNumber,
} from "@/helpers/product-utils";

@Component({
  computed: {
    ...mapState(["user"]),
  },
})
export default class ProductCard extends Vue {
  @Prop() product!: Product;
  isFav = false;

  getImageSrc(productNumber) {
    return getProductImageUrl(productNumber);
  }

  getProductDescription(description) {
    return getProductDescription(description, 50);
  }

  get calculateNormalPrice() {
    return getNormalPrice(
      this.product.Precio,
      this.product.PorcentajeDescuento
    );
  }

  addWishlist() {
    this.isFav = !this.isFav;
  }
  goToProductView() {
    this.$store.commit("setProduct", this.product);
    this.$nextTick(() => {
      this.$router.push({
        path: `/product/${formatProductNumber(this.product.NoArticulo)}-${
          this.product.Precio
        }`,
      });
    });
  }
  addToShoppingCart() {
    const newProductToCart = {
      ...this.product,
      quantity: 1,
    };
    this.$store.commit("addProductToCart", newProductToCart);
    this.$nextTick(() => {
      messagesAlert(
        "success",
        "Éxito",
        "El artículo se ha agregado correctamente a su carrito"
      );
      putShoppingCart(this.$store.state.shoppingCart.products);
    });
  }
  buyNow() {
    const newProductToCart = {
      ...this.product,
      quantity: 1,
    };
    this.$store.commit("addProductToCart", newProductToCart);
    this.$nextTick(() => {
      putShoppingCart(this.$store.state.shoppingCart.products);
      this.$router.push({
        name: "shoppingCart",
      });
    });
  }
}
