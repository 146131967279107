




















































































































































































































































































































































































import { Component, Vue, Ref } from "vue-property-decorator";
import CarouselPromotions from "@/components/Carousel/CarouselPromotions.vue";
import { Swiper, Thumbs, Navigation } from "swiper";
import { mapState } from "vuex";
import { messagesAlert } from "@/plugins/toastr";
import { putShoppingCart } from "@/services/shoppingCarServices";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import {
  getProductImageUrl,
  getProductDetailedPDFUrl,
  getInformationProductFormat,
  getNormalPrice,
} from "@/helpers/product-utils";
import { getFilterProduct } from "@/services/productServices";
import SimpleLoader from "@/components/Alerts/SimpleLoader.vue";

@Component({
  computed: {
    ...mapState(["products", "user"]),
  },
  components: {
    CarouselPromotions,
    SimpleLoader,
  },
})
export default class ProductView extends Vue {
  @Ref("swiper-product-view") swiperRoot!: HTMLDivElement;
  @Ref("swiper-product-view-thumbs") swiperThumb!: HTMLDivElement;
  quantity = 1;
  isFav = false;
  isLoadingProduct = false;
  getImages() {
    return [getProductImageUrl(this.$store.state.products.product.NoArticulo)];
  }

  mounted() {
    this.isLoadingProduct = true;
    const productUrlInfo = this.$route.params.id.split("-");
    let filterProduct = {
      NoArticulo: productUrlInfo[0],
      NumeroPagina: 1,
      RegistrosPorPagina: 1,
      PrecioMin: productUrlInfo[1],
      PrecioMax: productUrlInfo[1],
    };
    getFilterProduct(filterProduct)
      .then((res: any) => {
        if (res.Productos?.Productos?.length > 0) {
          res.Productos.Productos[0].DescripcionLarga =
            getInformationProductFormat(
              res.Productos.Productos[0].DescripcionLarga
            );
          this.$store.commit("setProduct", res.Productos.Productos[0]);
        }
      })
      .then(() => {
        const thumbs = new Swiper(this.swiperThumb, {
          spaceBetween: 12,
          slidesPerView: 4,
        });

        new Swiper(this.swiperRoot, {
          modules: [Thumbs, Navigation],
          spaceBetween: 4,
          centeredSlidesBounds: true,
          navigation: {
            nextEl: "#swiper-button-next-product-view",
            prevEl: "#swiper-button-prev-product-view",
          },
          thumbs: {
            swiper: thumbs,
          },
        });
        this.isLoadingProduct = false;
      })
      .catch((err) => {
        this.isLoadingProduct = false;
      });
  }

  addToShoppingCart(type) {
    if (this.quantity > 0) {
      const newProductToCart = {
        ...this.$store.state.products.product,
        quantity: this.quantity,
      };
      this.$store.commit("addProductToCart", newProductToCart);
      this.$nextTick(() => {
        putShoppingCart(this.$store.state.shoppingCart.products);
        if (type == "standar") {
          messagesAlert(
            "success",
            "Éxito",
            "El artículo se ha agregado correctamente a su carrito"
          );
        } else {
          this.$router.push({
            name: "shoppingCart",
          });
        }
      });
    } else {
      messagesAlert(
        "warning",
        "Faltan productos",
        "Debe de agregar al menos elemento"
      );
    }
  }

  downloadProductDoc() {
    window.open(
      getProductDetailedPDFUrl(this.$store.state.products.product.NoArticulo),
      "_blank"
    );
  }

  addWishlist() {
    this.isFav = !this.isFav;
  }

  shareProduct() {
    const bodyText = "¡Hola!, Me gustaría compartirte el siguiente producto";
    const productURL = `${process.env.VUE_APP_PATH}${this.$route.fullPath}`;
    if (["xl", "lg"].includes(this.$vuetify.breakpoint.name)) {
      window.open(
        `mailto:?subject=${this.$store.state.products.product.Descripcion}&body=${bodyText}%0A%0A${productURL}%0A%0AEs una ${this.$store.state.products.product.Descripcion}`
      );
    } else {
      const shareData = {
        title: this.$store.state.products.product.Descripcion,
        text: bodyText,
        url: productURL,
      };
      navigator.share(shareData);
    }
  }

  changeQuantity(type) {
    if (type === "plus") {
      this.quantity++;
    }

    if (type === "minus" && this.quantity > 0) {
      this.quantity--;
    }
  }

  get calculateNormalPrice() {
    return getNormalPrice(
      this.$store.state.products.product.Precio,
      this.$store.state.products.product.PorcentajeDescuento
    );
  }

  destroyed() {
    this.$store.commit("clearProduct");
  }
}
