import Vue from "vue";

const HOST_API_USER = "/api/v1/users";
const HOST_API_RATES = "/api/v1/rates";

const getAddress = (pageSize, pageNum) => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .get(`${HOST_API_USER}/address?pageSize=${pageSize}&pageNum=${pageNum}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const saveNewAddress = (newAddress) => {
  return new Promise((resolve, reject) => {
    newAddress.zip_code = parseInt(newAddress.zip_code, 10);
    if (newAddress.internal_number == null) {
      delete newAddress.internal_number;
    }
    Vue.axios
      .post(`${HOST_API_USER}/address`, newAddress)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const editAddress = (id, newAddressInfo) => {
  return new Promise((resolve, reject) => {
    newAddressInfo.zip_code = parseInt(newAddressInfo.zip_code, 10);
    delete newAddressInfo.address_id;
    if (newAddressInfo.internal_number == null) {
      delete newAddressInfo.internal_number;
    }
    Vue.axios
      .patch(`${HOST_API_USER}/address/${id}`, newAddressInfo)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const deleteAddress = (id) => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .delete(`${HOST_API_USER}/address/${id}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getCPInfo = (zipCode) => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .get(`${HOST_API_RATES}/${zipCode}/info`)
      .then((res) => {
        if (!res.data.location.city)
          res.data.location.city = res.data.location.municipality;
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export { getAddress, saveNewAddress, editAddress, deleteAddress, getCPInfo };
