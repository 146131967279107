































import { Component, Vue, Ref } from "vue-property-decorator";
import { mapState } from "vuex";
import AccountItem from "@/components/Profile/AccountItem.vue";
import NewPaid from "@/components/Profile/NewPaid.vue";
import SimpleLoader from "@/components/Alerts/SimpleLoader.vue";
import { validations } from "@/helpers/constants";
import { getCards } from "@/services/openPayServices";
import { messagesAlert } from "@/plugins/toastr";

@Component({
  computed: {
    ...mapState(["paidMethod"]),
  },
  components: {
    AccountItem,
    SimpleLoader,
    NewPaid,
  },
})
export default class MyPaid extends Vue {
  @Ref("newCardForm") readonly newCardForm!: VForm;
  fields = validations;
  mounted() {
    getCards().catch((err) => {
      this.$store.commit("setPaidMethods", []);
      messagesAlert(
        "error",
        "¡Error!",
        "Ha habido un error al momento de cargar su información"
      );
    });
  }
  hideShowForm() {
    this.$store.commit(
      "showNewForm",
      !this.$store.state.paidMethod.showNewForm
    );
    this.$store.commit("cleanPaidMethodForm");
  }
}
