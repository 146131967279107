



























































































































































































































































































































































import {
  myProfile,
  editProfile,
  getMyBillingData,
  createBillingData,
  updateBillingData,
} from "@/services/accountServices";
import { getCPInfo } from "@/services/addressService";
import { changePassword } from "@/services/accountServices";
import { login } from "@/services/authServices";
import { Component, Vue, Ref } from "vue-property-decorator";
import { messagesAlert } from "@/plugins/toastr";
import SimpleLoader from "@/components/Alerts/SimpleLoader.vue";
import NewPasswordForm from "@/components/Profile/NewPasswordForm.vue";
import Crypto from "crypto-js";
import { validations, cfdi, taxRegime } from "@/helpers/constants";

@Component({
  components: {
    SimpleLoader,
    NewPasswordForm,
  },
})
export default class MyAccount extends Vue {
  @Ref("profileForm") readonly profileForm!: VForm;
  @Ref("changePasswordForm") readonly changePasswordForm!: VForm;
  @Ref("billingForm") readonly billingForm!: VForm;
  validations = validations;
  cfdiTypes = cfdi;
  taxRegimeTypes = taxRegime;
  modalChangePassord = false;
  modalBillingGuide = false;
  birthdayMenu = false;
  loadingSignupForm = false;
  loadingMyAccount = false;
  loadingChangePassword = false;
  loadingBillingForm = false;
  profile = {
    first_name: null,
    last_name: null,
    email: null,
    password: null,
    birthday: null,
    gender: null,
    state: null,
    city: null,
    country: null,
    zip_code: "",
  };
  billingData = {
    billing_id: null,
    tax_regime: "",
    business_name: "",
    rfc: "",
    zip_code: "",
    cfdi_use: "",
    email: "",
    legalRepresentant: "",
  };
  mounted() {
    this.getMyProfile();
  }
  getMyProfile() {
    this.loadingMyAccount = true;
    myProfile()
      .then((res: any) => {
        this.loadingMyAccount = false;
        this.profile = res.data;
      })
      .catch((err) => {
        this.loadingMyAccount = false;
        messagesAlert(
          "error",
          "¡Error!",
          "Ha habido un error al momento de cargar su usuario"
        );
        console.log(err);
      });
    getMyBillingData().then((res: any) => {
      if (typeof res == "object") {
        this.billingData = res;
        this.$store.commit("setBilling", res);
      }
    });
  }
  updateProfile() {
    this.loadingSignupForm = true;
    if (this.profileForm.validate()) {
      editProfile(this.profile)
        .then((res) => {
          this.getMyProfile();
          const decrypt = Crypto.AES.decrypt(
            localStorage.getItem("user"),
            "elunicornio115_user"
          );
          const user = JSON.parse(decrypt.toString(Crypto.enc.Utf8));
          this.$store.commit("setUser", user);
          this.successMessageAlert();
          this.loadingSignupForm = false;
        })
        .catch((err) => {
          this.errorMessageAlert();
          this.loadingSignupForm = false;
          console.log(err);
        });
    } else {
      this.loadingSignupForm = false;
    }
  }
  validateCPInfo() {
    const zip_code = this.profile.zip_code || "";
    if (zip_code.length === 5) {
      getCPInfo(zip_code)
        .then((res: any) => {
          this.profile.country = res.location.estate;
          this.profile.city = res.location.city;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  saveNewPassword(newPassord) {
    this.loadingChangePassword = true;
    const token = this.$store.state.user.user.cypher;
    if (this.changePasswordForm.validate()) {
      const auth = {
        username: this.profile.email,
        password: newPassord.password,
      };
      login(auth)
        .then((res) => {
          changePassword(newPassord.newPassword, token)
            .then(() => {
              this.getMyProfile();
              messagesAlert(
                "success",
                "¡Exito!",
                "La contraseña se ha actualizado correctamente",
                () => {
                  this.modalChangePassord = false;
                  this.loadingChangePassword = false;
                }
              );
            })
            .catch(() => {
              messagesAlert(
                "error",
                "¡Error!",
                "Ha habido un error al momento de cambiar la contraseña"
              );
              this.loadingChangePassword = false;
            });
          this.loadingChangePassword = false;
        })
        .catch((err) => {
          messagesAlert("error", "¡Error!", err.data.message);
          this.loadingChangePassword = false;
        });
    } else {
      this.loadingChangePassword = false;
    }
  }
  updateBillingData() {
    if (this.billingForm.validate()) {
      this.loadingBillingForm = true;
      if (this.billingData.billing_id == null) {
        createBillingData(this.billingData)
          .then(() => {
            this.successMessageAlert();
            this.getMyProfile();
            this.loadingBillingForm = false;
            this.$store.commit("showBilligData", false);
          })
          .catch((err) => {
            this.errorMessageAlert();
            this.loadingBillingForm = false;
            console.log(err);
          });
      } else {
        updateBillingData(this.billingData)
          .then(() => {
            this.successMessageAlert();
            this.getMyProfile();
            this.loadingBillingForm = false;
            this.$store.commit("showBilligData", false);
          })
          .catch((err) => {
            this.errorMessageAlert();
            console.log(err);
            this.loadingBillingForm = false;
          });
      }
    }
  }
  successMessageAlert() {
    messagesAlert(
      "success",
      "¡Exito!",
      "Su usuario se ha actualizado correctamente"
    );
  }
  errorMessageAlert() {
    messagesAlert(
      "error",
      "¡Error!",
      "Ha habido un error al momento de editar su usuario"
    );
  }

  get modalDimensions() {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
      case "sm":
        return 500;
      case "xl":
      case "md":
      case "lg":
      default:
        return 700;
    }
  }
}
