import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import filter from "@/store/modules/filter";
import order from "@/store/modules/orders";
import address from "@/store/modules/address";
import user from "@/store/modules/user";
import shoppingCart from "@/store/modules/shoppingCart";
import paidMethod from "@/store/modules/paidMethod";
import products from "@/store/modules/products";
import system from "@/store/modules/system";

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: "vuex",
  storage: window.localStorage,
});

const state = () => ({
  sapToken: null,
  deviceSessionId: null,
});

const actions = {
  setSAPToken({ commit }, token) {
    commit("setSAPToken", token);
  },
  cleanSAPToken({ commit }) {
    commit("cleanSAPToken");
  },
  setDeviceSessionToken({ commit }, token) {
    commit("setDeviceSessionToken", token);
  },
  cleanDevieceSessionToken({ commit }) {
    commit("cleanDevieceSessionToken");
  },
};

const mutations = {
  setSAPToken(state, token) {
    state.sapToken = token;
  },
  cleanSAPToken(state) {
    state.sapToken = null;
  },
  setDeviceSessionToken(state, token) {
    state.deviceSessionId = token;
  },
  cleanDevieceSessionToken(state) {
    state.deviceSessionId = null;
  },
};

export default new Vuex.Store({
  state,
  getters: {},
  mutations: { ...mutations },
  actions: { ...actions },
  modules: {
    filter,
    order,
    address,
    user,
    shoppingCart,
    paidMethod,
    products,
    system,
  },
  plugins: [vuexLocalStorage.plugin],
});
