













































import { Component, Vue } from "vue-property-decorator";
import ProductList from "@/components/Product/ProductList.vue";
import ProductFilter from "@/components/Product/ProductFilter.vue";

@Component({
  components: {
    ProductList,
    ProductFilter,
  },
})
export default class Products extends Vue {
  sheetFilters = false;
  productList = [];
  beforeDestroy() {
    this.$store.commit("cleanFilters");
  }
}
