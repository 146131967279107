












































import { Component, Vue, Prop } from "vue-property-decorator";
import { getProductImageUrl } from "@/helpers/product-utils";

@Component
export default class ChangeStockCard extends Vue {
  @Prop() product!: any;
  getImageSrc(productNumber) {
    return getProductImageUrl(productNumber);
  }
}
