
















































































































































































































import { mapState } from "vuex";
import {
  getAddress,
  saveNewAddress,
  editAddress,
  getCPInfo,
} from "@/services/addressService";
import { Component, Vue, Ref, Prop } from "vue-property-decorator";
import { messagesAlert } from "@/plugins/toastr";
import { validations } from "@/helpers/constants";

@Component({
  computed: {
    ...mapState(["address"]),
  },
})
export default class NewAddress extends Vue {
  @Ref("newAdressForm") readonly newAdressForm!: VForm;
  @Prop({ default: "profile" }) type!: string;
  @Prop({ default: false }) validate!: VForm;
  loadingForm = false;
  fields = validations;
  goAddressList() {
    this.$store.commit("setShowAddressForm", false);
    this.$store.commit("cleanAddressForm");
    this.newAdressForm.resetValidation();
    this.loadingForm = false;
  }
  saveAddress() {
    this.loadingForm = true;
    if (this.newAdressForm.validate()) {
      saveNewAddress(this.$store.state.address.addressForm)
        .then((res) => {
          messagesAlert(
            "success",
            "¡Éxito!",
            "Su dirección se ha guadado con éxito",
            () => {
              getAddress(0, 0)
                .then((res: any) => {
                  this.$store.commit("setAddress", res.data);
                })
                .then(() => {
                  this.goAddressList();
                });
            }
          );
        })
        .catch((err) => {
          this.loadingForm = false;
          messagesAlert(
            "error",
            "¡Error!",
            "Ha habido un error al momento de registrar su dirección"
          );
        });
    } else {
      this.loadingForm = false;
    }
  }
  saveEditAddress() {
    this.loadingForm = true;
    if (this.newAdressForm.validate()) {
      editAddress(
        this.$store.state.address.addressForm.address_id,
        this.$store.state.address.addressForm
      )
        .then((res) => {
          messagesAlert(
            "success",
            "¡Éxito!",
            "Su dirección se ha editado con éxito",
            () => {
              getAddress(0, 0)
                .then((res: any) => {
                  this.$store.commit("setAddress", res.data);
                })
                .then(() => {
                  this.goAddressList();
                });
            }
          );
        })
        .catch((err) => {
          this.loadingForm = false;
          messagesAlert(
            "error",
            "¡Error!",
            "Ha habido un error al momento de editar su dirección"
          );
        });
    } else {
      this.loadingForm = false;
    }
  }
  saveNoUserAddress() {
    if (this.newAdressForm.validate()) {
      this.$store.state.address.addressForm.zip_code = parseInt(
        this.$store.state.address.addressForm.zip_code,
        10
      );
      this.$store.commit("setAddress", [this.$store.state.address.addressForm]);
      this.$store.commit(
        "addAddressToCart",
        this.$store.state.address.addressForm
      );
      this.$store.commit("changeShoppingStatus", 2);
    }
  }
  validateCPInfo() {
    const zip_code = this.$store.state.address.addressForm.zip_code;
    const validStates = ["Yucatán", "Quintana Roo", "Campeche"];
    if (zip_code.length === 5) {
      getCPInfo(zip_code)
        .then((res: any) => {
          if (validStates.includes(res.location?.estate)) {
            this.$store.commit("setCity", res.location);
          } else {
            this.errorOnZipValidation();
          }
        })
        .catch((err) => {
          this.errorOnZipValidation();
        });
    }
  }
  errorOnZipValidation() {
    const defaultLocation = {
      estate: "",
      city: "",
    };
    this.$store.commit("setCity", defaultLocation);
    messagesAlert(
      "warning",
      "¡Fuera del área de servicio!",
      "La dirección a registrar se encuentra fuera de nuestro alcance, por favor, intente con otra"
    );
  }
}
