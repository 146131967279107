import Vue from "vue";
import store from "@/store";
import axios from "axios";

const HOST_API_PRODUCT_FILTER = `${process.env.VUE_APP_SAP_URL}/productos`;
const HOST_API_PRODUCT = "/api/v1/products";

const PRODUCTS_HEADER_WITH_TOKEN = {
  headers: {
    "X-ApiKey": `${process.env.VUE_APP_SAP_PRODUCTS_TOKEN || ""}`,
  },
};

const getFilterProduct = async (filter) => {
  return new Promise((resolve, reject) => {
    Object.keys(filter).forEach((f) => {
      if (
        typeof filter[f] == "undefined" ||
        filter[f] === null ||
        filter[f].length === 0 ||
        filter[f] == ""
      )
        delete filter[f];
    });
    Vue.axios
      .post(
        `${HOST_API_PRODUCT_FILTER}/ObtenerProductosFiltroCombinado`,
        {
          ...filter,
          PrecioMin: filter.PrecioMin || 0,
          PrecioMax: filter.PrecioMax || 1000000,
        },
        PRODUCTS_HEADER_WITH_TOKEN
      )
      .then((res: any) => {
        store.commit("clearProductList");
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getProductSections = () => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .get(`${HOST_API_PRODUCT}/sections?pageSize=10&pageNum=1`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getProductFamilies = (pageSize, pageNum) => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .get(`${HOST_API_PRODUCT}/families`, {
        params: {
          pageSize,
          pageNum,
        },
      })
      .then((res: any) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getProductCategories = (pageSize, pageNum) => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .get(`${HOST_API_PRODUCT}/categories`, {
        params: {
          pageSize,
          pageNum,
        },
      })
      .then((res: any) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getProductBrand = (pageSize, pageNum) => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .get(`${HOST_API_PRODUCT}/brands`, {
        params: {
          pageSize,
          pageNum,
        },
      })
      .then((res: any) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getProductSubcategories = (pageSize, pageNum) => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .get(`${HOST_API_PRODUCT}/subcategories`, {
        params: {
          pageSize,
          pageNum,
        },
      })
      .then((res: any) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getProductSuggest = (pageSize, pageNum) => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .get(`${HOST_API_PRODUCT}/suggest`, {
        params: {
          pageSize,
          pageNum,
        },
      })
      .then((res: any) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const validateStock = async (products) => {
  return new Promise((resolve) => {
    const productsOutOfStock: any[] = [];
    const requests: any[] = [];
    products.forEach((product) =>
      requests.push(getProductById(product.NoArticulo))
    );
    axios
      .all(requests)
      .then(
        axios.spread((...responses) => {
          responses.forEach((response) => {
            const updatedProduct = response.Productos.Productos[0];
            const relatedProduct = products.find(
              (product) => product.NoArticulo === updatedProduct.NoArticulo
            );
            if (updatedProduct.CantidadStock > 0) {
              const stockDiff =
                updatedProduct.CantidadStock - relatedProduct.quantity;

              if (stockDiff < 0) {
                relatedProduct.quantity = updatedProduct.CantidadStock;
                productsOutOfStock.push(relatedProduct);
              }
            } else {
              relatedProduct.quantity = 0;
              relatedProduct.CantidadStock = 0;
              productsOutOfStock.push(relatedProduct);
            }
          });
        })
      )
      .then(() => resolve(productsOutOfStock));
  });
};

const getProductById = (id) => {
  return Vue.axios.post(
    `${HOST_API_PRODUCT_FILTER}/ObtenerProductosFiltroCombinado`,
    { Articulo: id, NumeroPagina: 1, RegistrosPorPagina: 1 },
    PRODUCTS_HEADER_WITH_TOKEN
  );
};

export {
  getFilterProduct,
  getProductCategories,
  getProductSections,
  getProductBrand,
  getProductSubcategories,
  getProductFamilies,
  getProductSuggest,
  validateStock,
};
