































































import { Component, Vue, Ref } from "vue-property-decorator";
import { validations } from "@/helpers/constants";
import { getWarranty } from "@/services/shipmentServices";
import { messagesAlert } from "@/plugins/toastr";

@Component
export default class TrackMyWarranty extends Vue {
  @Ref("trackingWarrantyForm") readonly trackingWarrantyForm!: VForm;
  isLoadingSearch = false;
  warrantyForm = {
    orderId: "",
    email: "",
  };
  warrantyData: Warranty = {
    uuid: "",
    order_id: "",
    sku: "",
    product_name: "",
    client_name: "",
    client_phone: "",
    client_email: "",
    status: "",
    updatedAt: "",
    createdAt: "",
  };
  fields = validations;
  searchWarranty() {
    this.isLoadingSearch = true;
    if (this.trackingWarrantyForm.validate()) {
      getWarranty(
        1,
        1,
        this.warrantyForm.orderId.trim(),
        this.warrantyForm.email.trim()
      )
        .then((res: any) => {
          if (res.data.length > 0) {
            this.warrantyData = res.data[0];
            this.isLoadingSearch = false;
          } else {
            messagesAlert(
              "error",
              "¡Error!",
              "Ha habido un error al momento de realizar la búsqueda"
            );
            this.isLoadingSearch = false;
          }
        })
        .catch((err) => {
          this.isLoadingSearch = false;
          messagesAlert(
            "error",
            "¡Error!",
            "Ha habido un error al momento de realizar la búsqueda"
          );
        });
    } else {
      this.isLoadingSearch = false;
    }
  }

  getStatusDescription(status) {
    switch (status) {
      case "Dictamen":
        return (
          "Aquí es donde se determina si es procedente o no a garantía; en caso de que no, se le manda su" +
          " presupuesto de reparación o se le brinda una garantía de cortesía de acuerdo al proveedor"
        );
      case "Aceptación/Declinación de reparación":
        return "Cuando no procede la garantía, el cliente deberá decidir si acepta o no el presupuesto de reparación";
      case "Solicitud de piezas a proveedor":
        return "En caso de aceptación de reparación";
      default:
        return "";
    }
  }
}
