import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

axios.interceptors.response.use(
  (response) => {
    if (
      response.status === 200 ||
      response.status === 201 ||
      response.status == 204
    ) {
      return Promise.resolve(response?.data);
    } else {
      return Promise.reject(response);
    }
  },
  async (error) => {
    if (error.code == "ERR_NETWORK") {
      const errorFormat = {
        code: error.code,
        data: {
          message: error.message,
        },
      };
      return Promise.reject(errorFormat);
    } else {
      return Promise.reject(error.response?.data);
    }
  }
);

axios.defaults.timeout = 15000;
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL_API;
axios.defaults.headers.post = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

Vue.use(VueAxios, axios);
