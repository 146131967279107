



































































import { validations } from "@/helpers/constants";
import { Component, Vue, Ref, Prop } from "vue-property-decorator";
@Component
export default class NewPasswordForm extends Vue {
  @Prop({ default: true }) confirmLastPassword!: boolean;
  @Ref("changePasswordForm") readonly changePasswordForm!: VForm;
  validations = validations;
  loadingChangePassword = false;
  newPassword = {
    password: null,
    newPassword: null,
    repeatPassword: null,
  };
  saveNewPassword() {
    this.loadingChangePassword = true;
    if (this.changePasswordForm.validate()) {
      this.$emit("password", this.newPassword);
    }

    this.$nextTick(() => {
      this.loadingChangePassword = false;
    });
  }

  get passwordConfirmation() {
    return (
      this.newPassword.newPassword === this.newPassword.repeatPassword ||
      "Las contraseñas no coinciden"
    );
  }
}
