












































































































import { Component, Vue, Ref } from "vue-property-decorator";
import { validations } from "@/helpers/constants";
import { getTracking } from "@/services/shipmentServices";
import SimpleLoader from "@/components/Alerts/SimpleLoader.vue";
import { messagesAlert } from "@/plugins/toastr";
import moment from "moment";

@Component({
  components: {
    SimpleLoader,
  },
})
export default class TrackMyShippment extends Vue {
  @Ref("trackingForm") readonly trackingForm!: VForm;
  trackingNumber: any = "";
  trackInformation: Tracking = {
    trackingID: "",
    status: "",
    shipped_at: "",
    events: [],
  };
  lastTrackInformation: ShipperEvents = {
    area: "",
    status: "",
    updated_at: "",
  };
  loadingTracking = false;
  fields = validations;

  mounted() {
    if (this.$route.query.trackId) {
      this.trackingNumber = this.$route.query.trackId;
      this.$nextTick(() => {
        this.getTrackingInformation();
      });
    }
  }

  getTrackingInformation() {
    this.loadingTracking = true;
    if (this.trackingForm.validate()) {
      getTracking(this.trackingNumber.trim())
        .then((res: any) => {
          if (res.success) {
            this.trackInformation = res.data;
            this.lastTrackInformation =
              res.data.events[res.data.events.length - 1];
          }
        })
        .then(() => {
          this.trackingNumber = "";
          this.loadingTracking = false;
          this.$nextTick(() => {
            this.trackingForm.resetValidation();
          });
        })
        .catch((err) => {
          console.log(err);
          this.trackingNumber = "";
          this.loadingTracking = false;
          messagesAlert(
            "error",
            "¡Error!",
            "Ha ocurrido un error al momento de realizar la busqueda, intente más tarde"
          );
        });
    } else {
      this.loadingTracking = false;
    }
  }

  goToDHLDetails() {
    const DHL_URL =
      "https://www.dhl.com/mx-es/home/rastreo.html?tracking-id=" +
      this.trackInformation.trackingID.trim();
    window.open(DHL_URL, "_blank");
  }

  formatTrackDate(date, type) {
    if (type == "date") {
      return moment(date).locale("es").format("dddd, MMMM DD, YYYY");
    } else {
      return moment(date).locale("es").format("hh:mm");
    }
  }
}
