import firestore from "@/plugins/firestore";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";

const BANNERS_PATH = "banners";
const SETTINGS_PATH = "settings";
const ECOMMERCE_DOC_ID = "ecommerce-web";

const getBanners = () => {
  return new Promise((resolve, reject) => {
    getDocs(collection(firestore, BANNERS_PATH))
      .then((snapshot) => {
        const banners: any = [];
        snapshot.forEach((result) => banners.push(result.data()));
        resolve(banners);
      })
      .catch((err) => reject(err));
  });
};

const getSystemSettings = async () => {
  return new Promise((resolve, reject) => {
    const docRef = doc(firestore, SETTINGS_PATH, ECOMMERCE_DOC_ID);
    getDoc(docRef)
      .then((snapshot) => {
        resolve(snapshot.data());
      })
      .catch((err) => reject(err));
  });
};

export { getBanners, getSystemSettings };
