







































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
  getFilterProduct,
  getProductSuggest,
} from "@/services/productServices";
import CarouselProducts from "@/components/Carousel/CarouselProducts.vue";

@Component({
  components: {
    CarouselProducts,
  },
})
export default class Sections extends Vue {
  @Prop({ default: "Section", required: true }) title!: string;
  @Prop({ default: null }) link!: string;
  @Prop({ default: "DEFAULT" }) designType!: string;
  @Prop({ default: null }) code!: string;
  productList: Product[] = [];
  isLoading = false;
  mounted() {
    const filter = {
      CategoriaEcommerce: this.code,
      NumeroPagina: 1,
      RegistrosPorPagina: 12,
    };
    if (this.code != null) {
      this.isLoading = true;
      getFilterProduct(filter)
        .then((res: any) => {
          this.productList = [
            ...res.Productos.Productos,
            ...res.Productos.Productos,
            ...res.Productos.Productos,
            ...res.Productos.Productos,
          ];
        })
        .finally(() => {
          this.isLoading = false;
        });
    }

    if (this.designType == "RECOMENDED") {
      getProductSuggest(10, 1)
        .then((res: any) => {
          this.productList = res.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  goToProductLink() {
    this.$store.commit("setCategories", {
      value: this.code,
      title: this.title,
    });
    this.$router.push({
      path: this.link,
    });
  }
}
