





















































import { Vue, Component, Ref } from "vue-property-decorator";
import { validations } from "@/helpers/constants";
import { successAuthMessages } from "@/helpers/constants";
import { generateTokenRecover } from "@/services/accountServices";

@Component
export default class RecoverPass extends Vue {
  @Ref("recoverPassForm") readonly recoverPassForm!: VForm;
  loadingRecoverPass = false;
  credenciales = {
    username: null,
    password: null,
  };
  fields = validations;
  recoverPassRequest() {
    this.loadingRecoverPass = true;
    if (this.recoverPassForm.validate()) {
      generateTokenRecover(this.credenciales.username)
        .then((res) => {
          this.$router.push({
            name: "register_success",
            params: successAuthMessages["RECOVER_PASSWORD"],
          });
        })
        .catch((err) => {})
        .finally(() => {
          this.loadingRecoverPass = false;
        });
    } else {
      this.loadingRecoverPass = false;
    }
  }
}
