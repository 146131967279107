import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store";
import authRouter from "./authRouter";
import storeRouter from "./storeRouter";
import statusRouter from "./statusRouter";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [statusRouter, authRouter, storeRouter];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to: any, from, next) => {
  const auth = store.getters.getJWTUser;
  const privatePages = ["profile"];
  const loginPages = [
    "login",
    "register",
    "recover_password",
    "register_success",
    "activate-account",
    "change_password",
  ];
  if (privatePages.includes(to.name) && !auth) {
    next({ name: "login" });
  }
  if (loginPages.includes(to.name) && auth) {
    next({ name: "homepage" });
  } else next();
});

export default router;
