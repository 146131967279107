const defaultShoppingCartState = () => {
  return {
    addressSelected: null,
    sendBy: null,
    payMethodSelected: null,
    typeAddress: 1,
    stepperStatus: 1,
    deliverType: "",
    couponCode: null,
    paymentPlan: null,
    sapErrors: [],
    reference: "",
  };
};

const defaultShoppingCartProducts = () => {
  return {
    products: [],
    summary: {
      subtotal: 0,
      taxes: 0,
      shipping: 0,
      discount: 0,
    },
  };
};

const changeSummaryPrices = (state) => {
  state.summary.subtotal = state.products.reduce((prev, actual) => {
    return prev + actual.Precio * actual.quantity;
  }, 0);

  if (state.couponCode != null) {
    state.summary.discount = state.products.reduce((prev, actual) => {
      return (
        prev +
        actual.Precio * actual.quantity * (state.couponCode.discount_pc / 100)
      );
    }, 0);
  } else {
    state.summary.discount = 0;
  }
};

const onChangeShoppingCart = (state, reset = true) => {
  if (state.typeAddress == 0) {
    state.deliverType = "UNICORN";
  } else {
    state.deliverType = "";
  }
  if (reset) {
    state.summary.shipping = 0;
  }
};

// init State
const state = () => ({
  loadingCalculateShippment: false,
  ...defaultShoppingCartProducts(),
  ...defaultShoppingCartState(),
});

const actions = {
  refreshCart({ commit }, cart) {
    commit("refreshCart", cart);
  },
  setProductCart({ commit }, cart) {
    commit("setProductCart", cart);
  },
  setShipingCost({ commit }, cost) {
    commit("setShipingCost", cost);
  },
  cleanShipingCost({ commit }) {
    commit("cleanShipingCost");
  },
  updateProductToCart({ commit }, product) {
    commit("updateProductToCart", product);
  },
  addProductToCart({ commit }, product) {
    commit("addProductToCart", product);
  },
  removeProductToCart({ commit }, product) {
    commit("removeProductToCart", product);
  },
  changeQuantityProduct({ commit }, product) {
    commit("changeQuantityProduct", product);
  },
  addAddressToCart({ commit }, address) {
    commit("addAddressToCart", address);
  },
  addPayMethodToCart({ commit }, payMethod) {
    commit("addPayMethodToCart", payMethod);
  },
  changeShoppingStatus({ commit }, newStatus) {
    commit("changeShoppingStatus", newStatus);
  },
  cleanShoppingCart({ commit }) {
    commit("cleanShoppingCart");
  },
  cleanProductShoppingCart({ commit }) {
    commit("cleanProductShoppingCart");
  },
  setLoadingCost({ commit }, loading) {
    commit("setLoadingCost", loading);
  },
  setCouponCode({ commit }, code) {
    commit("setCouponCode", code);
  },
  addSapError({ commit }, error) {
    commit("addSapError", error);
  },
  setReference({ commit }, reference) {
    commit("setReference", reference);
  },
  setDeliveryType({ commit }, type) {
    commit("setDeliveryType", type);
  },
};

const mutations = {
  refreshCart(state, cart) {
    state.products = cart;
    changeSummaryPrices(state);
  },
  setProductCart(state, cart) {
    state.products = cart;
    changeSummaryPrices(state);
    onChangeShoppingCart(state, false);
  },
  setShipingCost(state, cost) {
    state.summary.shipping = cost;
  },
  cleanShipingCost(state) {
    state.summary.shipping = 0;
  },
  updateProductToCart(state, product) {
    const indexOnCart = state.products.findIndex(
      (p) => p.NoArticulo == product.NoArticulo
    );
    if (indexOnCart !== -1) {
      state.products[indexOnCart] = product
      changeSummaryPrices(state);
      onChangeShoppingCart(state);
    }
  },
  addProductToCart(state, product) {
    const indexOnCart = state.products.findIndex(
      (p) => p.NoArticulo == product.NoArticulo
    );
    if (indexOnCart === -1) {
      state.products.push(product);
    } else {
      state.products[indexOnCart].quantity += product.quantity;
    }
    changeSummaryPrices(state);
    onChangeShoppingCart(state);
  },
  removeProductToCart(state, product) {
    const indexOnCart = state.products.indexOf(product);
    if (indexOnCart != -1) {
      state.products.splice(indexOnCart, 1);
      changeSummaryPrices(state);
      onChangeShoppingCart(state);
    }
  },
  changeQuantityProduct(state, { product, type }) {
    const indexOnCart = state.products.indexOf(product);
    if (indexOnCart != -1) {
      if (type === "plus") {
        state.products[indexOnCart].quantity++;
      }

      if (type === "minus" && state.products[indexOnCart].quantity > 0) {
        state.products[indexOnCart].quantity--;
      }
    }
    if (product.quantity == 0) {
      state.products.splice(indexOnCart, 1);
    }
    changeSummaryPrices(state);
    onChangeShoppingCart(state);
  },
  addAddressToCart(state, address) {
    state.addressSelected = address;
  },
  addPayMethodToCart(state, payMethod) {
    state.payMethodSelected = payMethod;
    state.paymentPlan = null;
  },
  changeShoppingStatus(state, newStatus) {
    state.stepperStatus = newStatus;
  },
  cleanShoppingCart(state) {
    state.loadingCalculateShippment = false;
    Object.assign(state, defaultShoppingCartState());
  },
  cleanProductShoppingCart(state) {
    Object.assign(state, defaultShoppingCartProducts());
  },
  setLoadingCost(state, loading) {
    state.loadingCalculateShippment = loading;
  },
  setCouponCode(state, code) {
    state.couponCode = code;
    changeSummaryPrices(state);
  },
  addSapError(state, error) {
    state.sapErrors.push(error);
  },
  setReference(state, reference) {
    state.reference = reference;
  },
  setDeliveryType(state, type) {
    state.deliverType = type;
  },
};

const getters = {
  getSAPErrors(state) {
    return state.sapErrors;
  },
};

export default {
  namespace: true,
  state,
  actions,
  mutations,
  getters,
};
