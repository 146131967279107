










































































































































































































































































import { Vue, Component, Ref } from "vue-property-decorator";
import { register } from "@/services/authServices";
import { messagesAlert } from "@/plugins/toastr";
import { validations } from "@/helpers/constants";
import { getCPInfo } from "@/services/addressService";
import { successAuthMessages } from "@/helpers/constants";

@Component
export default class Register extends Vue {
  @Ref("signupForm") readonly signupForm!: VForm;
  birthdayMenu = false;
  loadingSignUp = false;
  register = {
    name: null,
    lastName: null,
    email: null,
    password: null,
    birthday: null,
    gender: null,
    state: "",
    city: "",
    country: "",
    tac: false,
    proms: false,
    zip_code: "",
  };
  confirmPassword = null;
  fields = validations;
  signUp() {
    this.loadingSignUp = true;
    if (this.signupForm.validate()) {
      register(this.register)
        .then((res: any) => {
          this.$store.commit("setUser", res.data);
        })
        .then(() => {
          this.$router.push({
            name: "register_success",
            params: successAuthMessages["ACTIVATE_ACCOUNT"],
          });
        })
        .catch((err) => {
          this.loadingSignUp = false;
          if (err.data.message.code === 11000) {
            messagesAlert(
              "error",
              "Correo inválido",
              "El correo ingresado ya está registrado"
            );
          } else {
            messagesAlert(
              "error",
              "Error",
              "Lo sentimos, ha ocurrido un error"
            );
          }
        });
    } else {
      this.loadingSignUp = false;
    }
  }
  validateCPInfo() {
    const zip_code = this.register.zip_code || "";
    if (zip_code.length === 5) {
      getCPInfo(zip_code)
        .then((res: any) => {
          this.register.country = res.location.estate;
          this.register.city = res.location.city;
        })
        .catch((err) => {
          this.register.country = "Yucatán";
          this.register.city = "Mérida";
          console.log(err);
        });
    }
  }
  // Computed
  get passwordConfirmation() {
    return (
      this.register.password === this.confirmPassword ||
      "Las contraseñas no coinciden"
    );
  }
}
