





































import { Component, Vue } from "vue-property-decorator";
import { getFilterProduct } from "@/services/productServices";
import { mapState } from "vuex";
import { messagesAlert } from "@/plugins/toastr";

@Component({
  computed: {
    ...mapState(["filter"]),
  },
})
export default class SearchBar extends Vue {
  loadingSearchBar: any = false;
  searchValue = "";
  searchBy = "description";
  async searchClick() {
    this.loadingSearchBar = "white";
    if (this.searchBy == "description") {
      this.$store.state.filter.Descripcion = this.searchValue;
      this.$store.state.filter.Articulo = "";
    } else {
      this.$store.state.filter.Articulo = this.searchValue;
      this.$store.state.filter.Descripcion = "";
    }
    await this.$store.commit("searchBarClean");
    getFilterProduct(this.$store.state.filter)
      .then((res: any) => {
        if (res.EsValido) {
          this.$store.commit("setProductList", res.Productos.Productos);
          this.$store.commit("setTotalRegisters", res.Productos.TotalRegistros);
        }
      })
      .then(() => {
        this.loadingSearchBar = false;
        if (this.$router.currentRoute.fullPath != "/home/products") {
          this.$router.push({
            name: "productList",
            params: {
              search: this.$store.state.filter.description,
            },
          });
        }
      })
      .catch((err) => {
        this.loadingSearchBar = false;
        messagesAlert(
          "error",
          "¡Error!",
          "Ha habido un error al momento de realizar la búsqueda"
        );
        console.log(err);
      });
  }
}
