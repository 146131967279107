import Vue from "vue";

const HOST_API_SHIPMENTS = "/api/v1/shipments";
const HOST_API_SHIPMENTS_RATE = "/api/v1/rates";
const HOST_API_WARRANTY = "/api/v1/warranty";

const getDHLRateShipment = (
  addressSelected,
  products: any[],
  shippmentDate
) => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .post(
        `${HOST_API_SHIPMENTS_RATE}`,
        getShipmentInformation(addressSelected, products, shippmentDate, addressSelected.email || "")
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getUnicornRateShipment = (zipCode) => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .get(`${HOST_API_SHIPMENTS_RATE}/validate?zip_code=${zipCode}`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getshipTrackings = (trackNumbers) => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .post(`${HOST_API_SHIPMENTS}/tracking`, {
        trackingIDs: trackNumbers,
      })
      .then((res: any) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getTracking = (trackNumber) => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .post(`${HOST_API_SHIPMENTS}/tracking`, { trackingIDs: [trackNumber] })
      .then((res: any) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const generateTracking = (
  addressSelected,
  products: any[],
  shippmentDate,
  type,
  typeSend,
  email
) => {
  return new Promise((resolve, reject) => {
    if (type == 1 && typeSend == "DHL") {
      Vue.axios
        .post(
          `${HOST_API_SHIPMENTS}`,
          getShipmentInformation(addressSelected, products, shippmentDate, email)
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      resolve({
        data: {
          labelId: "",
          labelPDF: "",
        },
      });
    }
  });
};

const getWarranty = (pageNum, pageSize, orderId, email) => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .get(`${HOST_API_WARRANTY}`, {
        params: {
          pageNum,
          pageSize,
          order_id: orderId,
          client_email: email,
        },
      })
      .then((res: any) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getShipmentInformation = (
  addressSelected,
  products: any[],
  shippmentDate,
  email
) => {
  return {
    recipient: {
      full_name: addressSelected.full_name,
      zip_code: addressSelected.zip_code.toString(),
      city: addressSelected.city,
      country_code: "MX",
      address1: `${addressSelected.address} #${addressSelected.external_number}`,
      address2: addressSelected.suburb,
      email,
      phone_number: addressSelected.phone_number,
    },
    packages: products.map((p) => {
      return {
        weight: p.Peso,
        length: p.Longitud,
        height: p.Altura,
        width: p.Ancho,
        quantity: parseInt(p.quantity, 10),
        declared_amount: p.Precio,
      };
    }),
    extra_params: {
      shipping_date: shippmentDate,
      product_code: "J",
      package_description: "Herramientas",
      is_pickup: false,
    },
  };
};

export {
  getshipTrackings,
  getTracking,
  getDHLRateShipment,
  getUnicornRateShipment,
  generateTracking,
  getWarranty,
};
