import Vue from "vue";
import Crypto from "crypto-js";

const HOST_API_USER = "/api/v1/users";

const login = (credential) => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .post(`${HOST_API_USER}/login`, {
        email: credential.username,
        password: credential.password,
      })
      .then((auth) => {
        localStorage.setItem(
          "user",
          Crypto.AES.encrypt(JSON.stringify(auth.data), "elunicornio115_user")
        );
        localStorage.setItem(
          "uuid",
          Crypto.AES.encrypt(auth.data.uuid, "elunicornio115_uuid")
        );
        localStorage.setItem(
          "jwt",
          Crypto.AES.encrypt(auth.data.jwt, "elunicornio115_jwt")
        );
        resolve(auth);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const register = (newUser) => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .post(`${HOST_API_USER}/signup`, {
        email: newUser.email,
        first_name: newUser.name,
        last_name: newUser.lastName,
        password: newUser.password,
        birthday: newUser.birthday,
        city: newUser.city,
        country: newUser.country,
        gender: newUser.gender,
        allow_marketing: newUser.proms,
        zip_code: newUser.zip_code,
      })
      .then((auth) => {
        resolve(auth);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export { login, register };
