import toastr from "toastr";
import "toastr/build/toastr.min.css";

toastr.options.showEasing = "swing";
toastr.options.closeButton = true;
toastr.options.closeMethod = "fadeOut";
toastr.options.closeDuration = 300;
toastr.options.closeEasing = "swing";
toastr.options.timeOut = 2000;
toastr.options.progressBar = true;
toastr.options.positionClass = "toast-bottom-center";

export const messagesAlert = (
  type: string,
  title: string,
  message: string,
  onHidden: Function = () => {}
) => {
  if (onHidden !== null) {
    toastr.options.onHidden = onHidden;
  }
  toastr[type](message, title);
};
