




























































import { Component, Vue } from "vue-property-decorator";

@Component
export default class ContactView extends Vue {
  mapsURL = "https://goo.gl/maps/pXdgCBArdqpvzQwF8";
  whatsAppLink = "https://wa.link/a3hq6z";
  email = "tiendaenlinea@materialeselunicornio.com.mx";
  goToMaps() {
    window.open(this.mapsURL, "_blank");
  }
  goToWhatsApp() {
    window.open(this.whatsAppLink, "_blank");
  }
  goToEmail() {
    window.open(`mailto:${this.email}`, "");
  }
}
