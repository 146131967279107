
















































































































































import moment from "moment";
import { getProductImageUrl } from "@/helpers/product-utils";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ProofPayment extends Vue {
  @Prop() orderItem!: any;
  formatDate(date) {
    return moment(date).format("DD/MM/YYYY");
  }
  getImageSrc(productNumber) {
    return getProductImageUrl(productNumber);
  }
}
