


































import { Vue, Component } from "vue-property-decorator";

@Component
export default class AuthSuccessView extends Vue {
  title: string = this.$route.params.title;
  message: string = this.$route.params.message;
  mounted() {
    if (!this.$route.params.title || !this.$route.params.message) {
      this.goToLoginPage();
    }
  }
  goToLoginPage() {
    this.$router.push({ path: "/login" });
  }
}
