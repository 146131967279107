








































































































































































































































import { Vue, Component } from "vue-property-decorator";
import { mapState } from "vuex";
import SeachBar from "@/components/SearchBar.vue";
import { messagesAlert } from "@/plugins/toastr";

@Component({
  computed: {
    ...mapState(["user", "shoppingCart"]),
    shoppingCartData() {
      const productsCart = this.$store.state.shoppingCart.products;
      return {
        totalArticles: productsCart.length,
      };
    },
  },
  components: {
    SeachBar,
  },
})
export default class Headbar extends Vue {
  userMenuBtn = false;
  loadingLogout = false;
  navigationRoutes = [
    {
      title: "Catálogo",
      link: "/home",
    },
    {
      title: "Acerca de nosotros",
      link: "/about_us",
    },
    {
      title: "Rastrear mi pedido",
      link: "/tracking",
    },
    {
      title: "Contáctanos",
      link: "/contact_us",
    },
  ];
  responsiveMenu = false;

  getMarginTopForSearchBar() {
    if (this.$vuetify.breakpoint.mdAndUp) return "";
    else return "mt-10";
  }

  getHeadBarClassByScreen() {
    if (this.$vuetify.breakpoint.mdAndUp) return "top-headbar-desktop";
    else return "top-headbar-mobile";
  }

  logout() {
    this.loadingLogout = true;
    this.$store.commit("cleanUser");
    this.$store.commit("cleanShoppingCart");
    this.$store.commit("cleanProductShoppingCart");
    this.$store.commit("setPaidMethods", []);
    localStorage.clear();
    this.loadingLogout = false;
    setTimeout(() => {
      this.$router.push("/home");
    }, 100);
    messagesAlert(
      "success",
      "¡Éxito!",
      "Se ha cerrado satisfactoriamente la sesión"
    );
  }
}
