const state = () => ({
  orders: [],
  orderDetail: {},
  showOrderDetail: false,
});

const actions = {
  setOrders({ state, commit }, orders) {
    commit("setOrders", orders);
  },
  setOrderDetail({ state, commit }, order) {
    commit("setOrderDetail", order);
  },
  cleanOrderDetail({ commit }) {
    commit("cleanOrderDetail");
  },
  setShowOrder({ state, commit }, show) {
    commit("setShowOrder", show);
  },
};

const mutations = {
  setOrders(state, orders) {
    state.setShowOrder = orders;
  },
  setOrderDetail(state, order) {
    state.orderDetail = order;
  },
  cleanOrderDetail(state) {
    state.orderDetail = {};
  },
  setShowOrder(state, show) {
    state.showOrderDetail = show;
  },
};

export default {
  namespace: true,
  state,
  actions,
  mutations,
};
