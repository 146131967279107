import Vue from "vue";
import store from "@/store";

const CUSTOMER_HOST = `${process.env.VUE_APP_OPEN_PAY_URL}${process.env.VUE_APP_OPEN_PAY_ID}/customers`;
const CHARGES_HOST = `${process.env.VUE_APP_OPEN_PAY_URL}${process.env.VUE_APP_OPEN_PAY_ID}/charges`;
const TOKENS_HOST = `${process.env.VUE_APP_OPEN_PAY_URL}${process.env.VUE_APP_OPEN_PAY_ID}/tokens`;

const REQUEST_HEADER = {
  headers: {
    "Content-type": "application/json",
  },
  auth: {
    username: process.env.VUE_APP_OPEN_PAY_PRIVATE_KEY || "",
    password: "",
  },
};

const saveNewCard = async (cardRequest) => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .post(
        `${CUSTOMER_HOST}/${store.getters.getOpenPayId}/cards`,
        cardRequest,
        REQUEST_HEADER
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const generateCardToken = (cardRequest, isUser) => {
  const urlToSaveCard = isUser
    ? `${CUSTOMER_HOST}/${store.getters.getOpenPayId}/cards`
    : `${process.env.VUE_APP_OPEN_PAY_URL}${process.env.VUE_APP_OPEN_PAY_ID}/cards`;
  return new Promise((resolve, reject) => {
    Vue.axios
      .post(`${TOKENS_HOST}`, cardRequest, REQUEST_HEADER)
      .then((res: any) => {
        if (res.card.brand != "amex") {
          Vue.axios
            .post(
              urlToSaveCard,
              {
                token_id: res.id,
                device_session_id: store.state.deviceSessionId,
              },
              REQUEST_HEADER
            )
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          reject({
            code: 402,
            message: "Lo sentimos, solo aceptamos tarjetas Visa o Mastercard",
          });
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getCards = () => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .get(
        `${CUSTOMER_HOST}/${store.getters.getOpenPayId}/cards`,
        REQUEST_HEADER
      )
      .then((res) => {
        store.commit("setPaidMethods", res);
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const editCreditCard = (cardId, cardRequest) => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .put(
        `${CUSTOMER_HOST}/${store.getters.getOpenPayId}/cards/${cardId}`,
        {
          holder_name: cardRequest.holder_name,
          cvv2: cardRequest.cvv2,
          expiration_month: cardRequest.expiration_month,
          expiration_year: cardRequest.expiration_year,
        },
        REQUEST_HEADER
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const deleteCard = (cardId) => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .delete(
        `${CUSTOMER_HOST}/${store.getters.getOpenPayId}/cards/${cardId}`,
        REQUEST_HEADER
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const generateCharges = (customer, cardId, totalToPaid, paymentPlan) => {
  const newCharge = {
    source_id: cardId,
    method: "card",
    amount: totalToPaid,
    currency: "MXN",
    description: "Compra de " + customer.first_name,
    device_session_id: store.state.deviceSessionId,
    payment_plan:
      paymentPlan == 1 || paymentPlan == null
        ? null
        : {
            payments: paymentPlan,
          },
    redirect_url: `${window.location.origin}/shopping_cart`,
    use_3d_secure: true,
  };
  let urlClientCharge = `${CUSTOMER_HOST}/${store.getters.getOpenPayId}/charges`;
  if (!store.getters.getOpenPayId) {
    newCharge["customer"] = {
      name: customer.first_name,
      last_name: customer.last_name,
      phone_number: "4423456723",
      email: customer.email,
    };
    urlClientCharge = CHARGES_HOST;
  }
  return new Promise((resolve, reject) => {
    Vue.axios
      .post(urlClientCharge, newCharge, REQUEST_HEADER)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const returnCharge = (transactionId, amount) => {
  const urlReturnCharge = `${CUSTOMER_HOST}/${store.getters.getOpenPayId}/charges/${transactionId}/refund`;
  const descriptionReturn = {
    description: `Devolución de ${amount}`,
    amount: amount,
  };
  return new Promise((resolve, reject) => {
    Vue.axios
      .post(urlReturnCharge, descriptionReturn, REQUEST_HEADER)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export {
  saveNewCard,
  getCards,
  deleteCard,
  editCreditCard,
  generateCharges,
  returnCharge,
  generateCardToken,
};
