


















































































import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { getAddress } from "@/services/addressService";
import MyProductsShoppingCart from "@/components/ShoppingCart/MyProductsShoppingCart.vue";
import AddressShoppingCart from "@/components/ShoppingCart/AddressShoppingCart.vue";
import PaidMethod from "@/components/ShoppingCart/PaidMethod.vue";
import SimpleLoader from "@/components/Alerts/SimpleLoader.vue";
import { messagesAlert } from "@/plugins/toastr";
import { getShoppingCart } from "@/services/shoppingCarServices";

@Component({
  computed: {
    ...mapState(["shoppingCart"]),
  },
  components: {
    MyProductsShoppingCart,
    AddressShoppingCart,
    PaidMethod,
    SimpleLoader,
  },
})
export default class ShoppingCart extends Vue {
  loadingMyAddress = false;
  destroyed() {
    this.$store.commit("cleanShipingCost");
    this.$store.commit("cleanShoppingCart");
    this.$store.commit("cleanPaidMethodForm");
    this.$store.commit("cleanAddressForm");
  }
  mounted() {
    getShoppingCart();
    if (!this.$store.state.shoppingCart.addressSelected?.zip_code) {
      this.$store.commit(
        "addAddressToCart",
        this.$store.getters.getDefaultAddress
      );
    }
    if (this.$store.state.user.jwt) this.getUserAddress();
  }
  getUserAddress() {
    this.loadingMyAddress = true;
    getAddress(0, 0)
      .then((res: any) => {
        this.$store.commit("setAddress", res.data);
        this.loadingMyAddress = false;
      })
      .catch((err) => {
        this.loadingMyAddress = false;
        messagesAlert(
          "error",
          "¡Error!",
          "Ha habido un error al momento de cargar sus direcciones"
        );
      });
  }
  backToAddressSelect() {
    this.$store.commit("cleanPaidMethodForm");
    this.$store.state.shoppingCart.payMethodSelected = null;
    this.$store.commit("changeShoppingStatus", 1);
  }
}
