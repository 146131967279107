import StoreLayout from "../layouts/Store.vue";
import Home from "../views/store/HomeView.vue";
import Product from "../views/store/ProductView.vue";
import Products from "../views/store/Products.vue";
import ProductsCategory from "@/views/store/ProductsCategory.vue";
import Profile from "@/views/store/ProfileView.vue";
import ShoppingCartView from "@/views/store/ShoppingCartView.vue";
import TrackMyWarranty from "@/views/store/TrackMyWarranty.vue";
import AboutUsView from "@/views/store/AboutUsView.vue";
import ContactUsView from "@/views/store/ContactUsView.vue";
import CategoriesView from "@/views/store/CategoriesView.vue";
import TrackMyShippment from "@/views/store/TrackMyShippment.vue";
import NotFoundView from "@/views/common/NotFoundView.vue";

export default {
  path: "/",
  redirect: "homepage",
  component: StoreLayout,
  children: [
    {
      path: "/home",
      name: "homepage",
      component: Home,
      meta: {
        title: "Home",
      },
    },
    {
      path: "/home/products",
      name: "productList",
      component: Products,
      meta: {
        title: "Productos",
      },
    },
    {
      path: "/home/products_category",
      name: "productListCategory",
      component: ProductsCategory,
      meta: {
        title: "Categoría de Productos",
      },
    },
    {
      path: "/home/categories",
      name: "categoryList",
      component: CategoriesView,
      meta: {
        title: "Categorías de Productos",
      },
    },
    {
      path: "/product/:id",
      name: "product",
      component: Product,
      meta: {
        title: "Producto",
      },
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      meta: {
        title: "Perfil",
      },
      props: {
        tab: null,
      },
    },
    {
      path: "/shopping_cart",
      name: "shoppingCart",
      component: ShoppingCartView,
      meta: {
        title: "Carrito de compras",
      },
    },
    {
      path: "/warranty",
      name: "warranty",
      component: TrackMyWarranty,
      meta: {
        title: "Seguimiento de garantía",
      },
    },
    {
      path: "/tracking",
      name: "tracking",
      component: TrackMyShippment,
      meta: {
        title: "Seguimiento de pedido",
      },
    },
    {
      path: "/about_us",
      name: "about_us",
      component: AboutUsView,
      meta: {
        title: "Acerca de nosotros",
      },
    },
    {
      path: "/contact_us",
      name: "contact_us",
      component: ContactUsView,
      meta: {
        title: "Contactanos",
      },
    },
    {
      path: "*",
      name: "not-found",
      component: NotFoundView,
      meta: {
        title: "404",
      },
    },
  ],
};
