





















































import { Component, Vue } from "vue-property-decorator";
import {
  getDHLRateShipment,
  getUnicornRateShipment,
} from "@/services/shipmentServices";
import SimpleLoader from "@/components/Alerts/SimpleLoader.vue";
import { mapState } from "vuex";
import { validations, zipCodesPlaya } from "@/helpers/constants";
import { messagesAlert } from "@/plugins/toastr";
import moment from "moment-timezone";

@Component({
  computed: {
    ...mapState({
      "shoppingCart": (state: any) => state.shoppingCart,
      "deliveryType": (state: any) => state.shoppingCart.deliverType
    }),
  },
  components: {
    SimpleLoader,
  },
})
export default class SendByForm extends Vue {
  fields = validations;
  modalIsNotReachable = false;
  updateDeliveryType(value) {
    this.$store.commit("setDeliveryType", value)
    this.$nextTick(() => {
      this.calculateShipmentPrice()
    })
  }
  calculateShipmentPrice() {
    this.$store.commit("cleanShipingCost");
    this.$store.commit("setLoadingCost", true);
    if (this.$store.state.shoppingCart.deliverType == "DHL") {
      getDHLRateShipment(
        this.$store.state.shoppingCart.addressSelected,
        this.$store.state.shoppingCart.products,
        moment().tz("America/Cancun").add(1, "day").format("YYYY-MM-DDT") +
          "16:00:00GMT-05:00"
      )
        .then((res: any) => {
          const domesticSend = res.data.find(
            (c) => c.service_type == "DOMESTICO ENVIO RETORNO"
          );
          this.$store.commit("setShipingCost", domesticSend?.cost);
        })
        .catch((err) => {
          this.errorMessage();
        })
        .finally(() => {
          this.$store.commit("setLoadingCost", false);
        });
    } else if (this.$store.state.shoppingCart.deliverType == "UNICORN") {
      const zipCodeToSearch =
        this.$store.state.shoppingCart.addressSelected.zip_code;
      getUnicornRateShipment(zipCodeToSearch)
        .then((res: any) => {
          if (res.data.isReachable && res.data.shipments_available.length > 0) {
            this.$store.commit(
              "setShipingCost",
              res.data.shipments_available[0].shipment_cost
            );
          } else if (zipCodesPlaya.includes(zipCodeToSearch.toString())) {
            this.$store.commit( "setShipingCost", 0 );
          } else {
            this.modalIsNotReachable = true;
            this.$store.state.shoppingCart.deliverType = "";
          }
        })
        .catch((err) => {
          this.errorMessage();
        })
        .finally(() => {
          this.$store.commit("setLoadingCost", false);
        });
    } else {
      this.$store.commit("setLoadingCost", false);
    }
  }

  errorMessage() {
    this.$store.state.shoppingCart.deliverType = "";
    this.$store.commit("setLoadingCost", false);
    messagesAlert(
      "error",
      "¡Error!",
      "Ha habido un error al momento de cotizar su envío, intente de nuevo"
    );
  }
}
