import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/vuetify";
import "@/assets/css/index.scss";
import "@/plugins/axios";
import "@/plugins/firestore";
import "@/plugins/vue-tag";
import "@/plugins/vue-meta";
import "@/plugins/vue-mask";
import { formatPrice } from "@/plugins/numeral";
import { initializeLocalStorage } from "@/helpers/booter";

initializeLocalStorage();

Vue.config.productionTip = false;
Vue.mixin({
  methods: {
    formatPrice,
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
