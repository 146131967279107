const validations = {
  required: (v) => !!v || "Este campo es obligatorio",
  email: (v) =>
    !v ||
    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
    "Correo electrónico no válido",
  lengthZipCode: (v) =>
    (!!v && v.toString().length == 5) || "No es un código postal válido",
  lenghtRfc: (v) => (!!v && v.length === 13) || "No es un RFC válido",
};

const cfdi = [
  {
    code: "G01",
    description: "Adquisición de mercancías",
    FISICA: true,
    MORAL: true,
  },
  {
    code: "G02",
    description: "Devoluciones, descuentos o bonificaciones",
    FISICA: true,
    MORAL: true,
  },
  { code: "G03", description: "Gastos en general", FISICA: true, MORAL: true },
  { code: "I01", description: "Construcciones", FISICA: true, MORAL: true },
  {
    code: "I02",
    description: "Mobiliario y equipo de oficina por inversiones ",
    FISICA: true,
    MORAL: true,
  },
  {
    code: "I03",
    description: "Equipo de transporte",
    FISICA: true,
    MORAL: true,
  },
  {
    code: "I04",
    description: "Equipo de cómputo y accesorios",
    FISICA: true,
    MORAL: true,
  },
  {
    code: "I05",
    description: "Dados, troqueles, moldes, matrices y herramental",
    FISICA: true,
    MORAL: true,
  },
  {
    code: "I06",
    description: "Comunicaciones telefónicas",
    FISICA: true,
    MORAL: true,
  },
  {
    code: "I07",
    description: "Comunicaciones satelitales",
    FISICA: true,
    MORAL: true,
  },
  {
    code: "I08",
    description: "Otra maquinaria y equipo",
    FISICA: true,
    MORAL: true,
  },
  {
    code: "D01",
    description: "Honorarios médicos, dentales y gastos hospitalarios",
    FISICA: true,
    MORAL: false,
  },
  {
    code: "D02",
    description: "Gastos médicos por incapacidad o discapacidad",
    FISICA: true,
    MORAL: false,
  },
  { code: "D03", description: "Gastos funerales.", FISICA: true, MORAL: false },
  { code: "D04", description: "Donativos", FISICA: true, MORAL: false },
  {
    code: "D05",
    description:
      "Intereses reales efectivamente pagados por créditos hipotecarios",
    FISICA: true,
    MORAL: false,
  },
  {
    code: "D06",
    description: "Aportaciones voluntarias al SAR",
    FISICA: true,
    MORAL: false,
  },
  {
    code: "D07",
    description: "Primas por seguros de gastos médicos",
    FISICA: true,
    MORAL: false,
  },
  {
    code: "D08",
    description: "Gastos de transportación escolar obligatoria",
    FISICA: true,
    MORAL: false,
  },
  {
    code: "D09",
    description:
      "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
    FISICA: true,
    MORAL: false,
  },
  {
    code: "D10",
    description: "Pagos por servicios educativos (colegiaturas)",
    FISICA: true,
    MORAL: false,
  },
  {
    code: "S01",
    description: "Sin efectos fiscales",
    FISICA: true,
    MORAL: true,
  },
  { code: "CP01", description: "Pagos", FISICA: true, MORAL: true },
  { code: "CN01", description: "Nómina", FISICA: true, MORAL: false },
];

const taxRegime = [
  {
    code: "601",
    description: "General de Ley Personas Morales",
    FISICA: false,
    MORAL: true,
  },
  {
    code: "603",
    description: "Personas Morales con Fines no Lucrativos",
    FISICA: false,
    MORAL: true,
  },
  {
    code: "605",
    description: "Sueldos y Salarios e Ingresos Asimilados a Salarios",
    FISICA: true,
    MORAL: false,
  },
  { code: "606", description: "Arrendamiento", FISICA: true, MORAL: false },
  {
    code: "607",
    description: "Régimen de Enajenación o Adquisición de Bienes",
    FISICA: true,
    MORAL: false,
  },
  { code: "608", description: "Demás ingresos", FISICA: true, MORAL: false },
  {
    code: "610",
    description:
      "Residentes en el Extranjero sin Establecimiento Permanente en México",
    FISICA: true,
    MORAL: true,
  },
  {
    code: "611",
    description: "Ingresos por Dividendos (socios y accionistas)",
    FISICA: true,
    MORAL: false,
  },
  {
    code: "612",
    description:
      "Personas Físicas con Actividades Empresariales y Profesionales",
    FISICA: true,
    MORAL: false,
  },
  {
    code: "614",
    description: "Ingresos por intereses",
    FISICA: true,
    MORAL: false,
  },
  {
    code: "615",
    description: "Régimen de los ingresos por obtención de premios",
    FISICA: true,
    MORAL: false,
  },
  {
    code: "616",
    description: "Sin obligaciones fiscales",
    FISICA: true,
    MORAL: false,
  },
  {
    code: "620",
    description:
      "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
    FISICA: false,
    MORAL: true,
  },
  {
    code: "621",
    description: "Incorporación Fiscal",
    FISICA: true,
    MORAL: false,
  },
  {
    code: "622",
    description: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
    FISICA: false,
    MORAL: true,
  },
  {
    code: "623",
    description: "Opcional para Grupos de Sociedades",
    FISICA: false,
    MORAL: true,
  },
  { code: "624", description: "Coordinados", FISICA: false, MORAL: true },
  {
    code: "625",
    description:
      "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
    FISICA: true,
    MORAL: false,
  },
  {
    code: "626",
    description: "Régimen Simplificado de Confianza",
    FISICA: true,
    MORAL: true,
  },
];

const successAuthMessages = {
  ACTIVATE_ACCOUNT: {
    title: "¡Listo, activa tu cuenta!",
    message:
      "Te hemos enviado un link de confirmación a tu correo, con la finalidad de validar tu identidad y finalizar el proceso de registro.",
  },
  RECOVER_PASSWORD: {
    title: "¡Listo!",
    message:
      "Te hemos enviado un link de recuperación de contraseña a tu correo.",
  },
};

const zipCodesPlaya = [
  "77710",
  "77712",
  "77713",
  "77714",
  "77716",
  "77717",
  "77720",
  "77723",
  "77724",
  "77725",
  "77726",
  "77727",
  "77728",
  "77730",
];

const metaInfo = {
  title: "Unicornio 115 - Tienda en línea",
  meta: [
    { property: "og:title", content: "Unicornio 115 - Tienda en línea" },
    {
      name: "description",
      content:
        "Tienda especializada en la venta de equipos de bombeo y presurización, herramientas eléctricas, " +
        "ferretería e iluminación, con un catálogo enfocado en ofrecer las mejores marcas y modelos.",
    },
    {
      property: "keywords",
      content:
        "Especialistas, Venta, Ferretería, Herramientas, Bombeo, Construcción, Hotelería, " +
        "Mejores precios, Envío rápido, Distribuidor autorizado",
    },
    { property: "og:site_name", content: "Unicornio 115 - Tienda en línea" },
    { property: "og:type", content: "website" },
    { property: "author", content: "VIACCE | Business & Technology" },
    { name: "robots", content: "index,follow" },
  ],
};

export {
  validations,
  cfdi,
  taxRegime,
  successAuthMessages,
  zipCodesPlaya,
  metaInfo,
};
