















































































import { Component, Vue, Prop } from "vue-property-decorator";
import { deleteCard, getCards } from "@/services/openPayServices";
import { messagesAlert } from "@/plugins/toastr";

@Component
export default class AccountItem extends Vue {
  @Prop() paidMethod!: any;
  @Prop({ default: true }) showDetail!: boolean;
  deleteModal = false;
  loadingDeletePaidMethod = false;
  applyPaidMethod() {
    this.$store.commit("setCardForm", {
      id: this.paidMethod.id,
      card_number: this.paidMethod.card_number,
      holder_name: this.paidMethod.holder_name,
      expiration_month: this.paidMethod.expiration_month,
      expiration_year: this.paidMethod.expiration_year,
    });
  }
  openModalDeletePaidMethod() {
    this.applyPaidMethod();
    this.deleteModal = true;
  }
  deletePaidMethod() {
    this.loadingDeletePaidMethod = true;
    deleteCard(this.$store.state.paidMethod.newCardForm.id)
      .then((res) => {
        this.cancelDeletePaidMethod();
        getCards();
      })
      .catch(() => {
        this.loadingDeletePaidMethod = false;
      });
  }
  cancelDeletePaidMethod() {
    this.$store.commit("cleanPaidMethodForm");
    this.deleteModal = false;
    this.loadingDeletePaidMethod = false;
  }
}
