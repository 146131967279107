





























































































import { Component, Vue, Prop } from "vue-property-decorator";
import { deleteAddress, getAddress } from "@/services/addressService";
import { messagesAlert } from "@/plugins/toastr";

@Component
export default class AddressItem extends Vue {
  @Prop({ default: true }) showOptions!: boolean;
  @Prop() address!: Object;
  deleteModal = false;
  goToEditAddress() {
    this.$store.commit("setAddressForm", this.address);
    this.$store.commit("setShowAddressForm", true);
  }
  deleteAddress(address_id) {
    deleteAddress(address_id)
      .then((res) => {
        getAddress(0, 0).then((res: any) => {
          this.$store.commit("setAddress", res.data);
        });
      })
      .catch((err) => {
        messagesAlert(
          "error",
          "¡Error!",
          "Ha habido un error al momento de eliminar su dirección"
        );
        console.log(err);
      });
  }
}
