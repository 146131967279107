














import { Vue, Component } from "vue-property-decorator";

@Component
export default class InMaintenanceView extends Vue {
  mounted() {
    if (!this.$store.getters.isMaintenanceMode)
      return this.$router.replace("/");
  }
}
