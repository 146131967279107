


























































































import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { couponRequest } from "@/services/shoppingCarServices";
import { messagesAlert } from "@/plugins/toastr";

@Component({
  computed: {
    ...mapState(["shoppingCart"]),
  },
})
export default class CouponForm extends Vue {
  modalCouponOffer = false;
  couponCode = "";
  addCouponCode() {
    couponRequest(this.couponCode, false)
      .then((coupon: any) => {
        this.$store.commit("setCouponCode", coupon.data);
        this.couponCode = "";
        messagesAlert(
          "success",
          "¡Éxito!",
          "Se ha canjeado tu cupón con éxito",
          () => (this.modalCouponOffer = false)
        );
      })
      .catch((err) => {
        messagesAlert("error", "¡Error!", err);
      });
  }
  resetCoupon() {
    this.closeDialog();
    this.$store.commit("setCouponCode", null);
  }

  closeDialog() {
    this.modalCouponOffer = false;
  }
}
