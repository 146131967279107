import CommonLayout from "@/layouts/Status.vue";
import InMaintenanceView from "@/views/common/InMaintenanceView.vue";

export default {
  path: "/status",
  component: CommonLayout,
  children: [
    {
      path: "/maintenance",
      name: "maintenance",
      component: InMaintenanceView,
      meta: {
        title: "En mantenimiento",
      },
    },
  ],
};
