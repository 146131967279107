import Vue from "vue";

const defaultUserState = () => {
  return {
    user: {},
    billingData: {},
    uuid: null,
    jwt: null,
    showBillingForm: false,
  };
};

// init State
const state = defaultUserState();

const actions = {
  showBilligData({ commit }, show) {
    commit("showBilligData", show);
  },
  setUser({ commit }, user) {
    commit("setUser", user);
  },
  setBilling({ commit }, billing) {
    commit("setBilling", billing);
  },
  cleanUser({ commit }) {
    commit("cleanUser");
  },
};

const getters = {
  getJWTUser(state) {
    if (typeof state.user == "object" && state.user.jwt) {
      return state.jwt;
    }
  },
  getBillingUser(state) {
    if (typeof state.user == "object" && state.billingData?.rfc) {
      return state.billingData;
    }
  },
  getUserId(state) {
    if (typeof state.user == "object" && state.user.uuid) {
      return state.user.uuid;
    }
  },
  getOpenPayId(state) {
    if (typeof state.user == "object" && state.user.openpay_id) {
      return state.user.openpay_id;
    }
  },
};

const mutations = {
  showBilligData(state, show) {
    state.showBillingForm = show;
  },
  setBilling(state, billing) {
    state.billingData = billing;
  },
  setUser(state, user) {
    state.user = user;
    state.uuid = user.uuid;
    state.jwt = user.jwt;
    Vue.axios.defaults.headers.get = {
      Authorization: "Bearer " + user.jwt,
    };
    Vue.axios.defaults.headers.post = {
      Authorization: "Bearer " + user.jwt,
    };
    Vue.axios.defaults.headers.patch = {
      Authorization: "Bearer " + user.jwt,
    };
    Vue.axios.defaults.headers.delete = {
      Authorization: "Bearer " + user.jwt,
    };
  },
  cleanUser(state) {
    Object.assign(state, defaultUserState());
  },
};

export default {
  namespace: true,
  state,
  actions,
  getters,
  mutations,
};
