































































import { Component, Vue, Prop } from "vue-property-decorator";
import { getProductImageUrl } from "@/helpers/product-utils";

@Component
export default class OrderItemProduct extends Vue {
  @Prop({ required: true }) productOrder!: any;
  @Prop({ required: true }) total!: number;
  getImageSrc(productNumber) {
    return getProductImageUrl(productNumber);
  }
}
