



















































































import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";

@Component({
  computed: {
    ...mapState(["shoppingCart"]),
  },
})
export default class SummaryShoppingCard extends Vue {}
