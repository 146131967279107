




























































import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import MyAccount from "@/components/Profile/MyAccount.vue";
import MyOrders from "@/components/Profile/MyOrders.vue";
import OrderDetail from "@/components/Profile/OrderDetails.vue";
import MyList from "@/components/Profile/MyList.vue";
import MyPaid from "@/components/Profile/MyPaid.vue";
import MyAddress from "@/components/Profile/MyAddress.vue";
import NewAddress from "@/components/Profile/NewAddress.vue";

@Component({
  components: {
    MyAccount,
    MyOrders,
    MyList,
    MyPaid,
    OrderDetail,
    MyAddress,
    NewAddress,
  },
  computed: {
    ...mapState(["address", "order"]),
    windowsSize() {
      if (["xs", "sm"].includes(this.$vuetify.breakpoint.name)) {
        return false;
      } else {
        return true;
      }
    },
  },
})
export default class ProfileView extends Vue {
  profileTab: any = 0;
  mounted() {
    if (this.$route.params.tab) {
      this.profileTab = parseInt(this.$route.params.tab, 10);
    }
  }
  defaultForms() {
    // Paid methods
    this.$store.commit("showNewForm", false);
    this.$store.commit("cleanPaidMethodForm");

    // Address
    this.$store.commit("setShowAddressForm", false);
    this.$store.commit("cleanAddressForm");

    //Account
    this.$store.commit("showBilligData", false);

    // Orders
    this.$store.commit("setShowOrder", false);
  }
  destroyed() {
    this.defaultForms();
  }
}
