







































































































































































































import { Component, Vue, Ref, Prop } from "vue-property-decorator";
import { mapState } from "vuex";
import moment from "moment";
import { validations } from "@/helpers/constants";
import { getCards, generateCardToken } from "@/services/openPayServices";
import { messagesAlert } from "@/plugins/toastr";

@Component({
  computed: {
    ...mapState(["paidMethod", "user"]),
  },
})
export default class NewPaid extends Vue {
  @Ref("refNewCardForm") readonly refNewCardForm!: VForm;
  @Prop({ default: "profile" }) type!: string;
  loadingSaveForma = false;
  fields = validations;
  years: any[] = [];
  months = [
    { month: "Enero", value: "01" },
    { month: "Febrero", value: "02" },
    { month: "Marzo", value: "03" },
    { month: "Abril", value: "04" },
    { month: "Mayo", value: "05" },
    { month: "Junio", value: "06" },
    { month: "Julio", value: "07" },
    { month: "Agosto", value: "08" },
    { month: "Septiembre", value: "09" },
    { month: "Octubre", value: "10" },
    { month: "Noviembre", value: "11" },
    { month: "Diciembre", value: "12" },
  ];
  mounted() {
    const actualYear = moment().year();
    for (let index = 0; index < 10; index++) {
      this.years.push({
        year: actualYear + index,
        value: `${actualYear + index}`.substring(2),
      });
    }
  }
  addTokenCard() {
    this.loadingSaveForma = true;
    const isUser = this.$store.state.user.jwt != null;
    if (this.refNewCardForm.validate()) {
      generateCardToken(this.$store.state.paidMethod.newCardForm, isUser)
        .then((res: any) => {
          if (isUser) {
            this.updateCardList();
            this.cancelForm();
          } else {
            this.$store.commit("showNewForm", false);
            this.$store.commit("setPaidMethods", [res]);
            this.$store.commit("addPayMethodToCart", res);
          }
        })
        .catch((err) => {
          if (err.code && err.code == 402) {
            messagesAlert("error", "¡Error!", err.message);
          } else {
            messagesAlert(
              "error",
              "¡Error!",
              "Ha habido un error al momento de guardar su información"
            );
          }
        })
        .finally(() => {
          this.loadingSaveForma = false;
        });
    } else {
      this.loadingSaveForma = false;
    }
  }
  updateCardList() {
    getCards().catch(() => {
      this.$store.commit("setPaidMethods", []);
      messagesAlert(
        "error",
        "¡Error!",
        "Ha habido un error al momento de cargar su información"
      );
    });
  }
  cancelForm() {
    this.refNewCardForm.resetValidation();
    this.refNewCardForm.reset();
    this.$store.commit("cleanPaidMethodForm");
    this.$store.commit("showNewForm", false);
    this.loadingSaveForma = false;
  }
  validateNumbers() {
    if (this.$store.state.paidMethod.newCardForm.card_number.length > 16) {
      this.$store.state.paidMethod.newCardForm.card_number =
        this.$store.state.paidMethod.newCardForm.card_number
          .toString()
          .substring(0, 14);
    }
  }
}
