
























































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class BuyedListItem extends Vue {
  quantity = 5;
  changeQuantity(type) {
    if (type === "plus") {
      this.quantity++;
    }

    if (type === "minus" && this.quantity > 0) {
      this.quantity--;
    }
  }
}
