import Vue from "vue";
import moment from "moment";
import Crypto from "crypto-js";
const HOST_API_USER = "/api/v1/users/account";
const HOST_USER_BILLING = "/api/v1/users/billing";

const myProfile = () => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .get(`${HOST_API_USER}/info`)
      .then((res: any) => {
        res.data.birthday = moment(res.data.birthday).format("YYYY-MM-DD");
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getMyBillingData = () => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .get(`${HOST_USER_BILLING}`)
      .then((res) => {
        if (res.data.length > 0) {
          resolve(res.data[0]);
        } else {
          resolve({});
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const createBillingData = (billingData) => {
  return new Promise((resolve, reject) => {
    delete billingData.billing_id;
    Vue.axios
      .post(`${HOST_USER_BILLING}`, billingData)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const updateBillingData = (billingData) => {
  return new Promise((resolve, reject) => {
    const billingId = billingData.billing_id;
    delete billingData.billing_id;
    Vue.axios
      .patch(`${HOST_USER_BILLING}/${billingId}`, billingData)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const editProfile = (newUserInfo) => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .patch(`${HOST_API_USER}/info`, newUserInfo)
      .then((res: any) => {
        const decrypt = Crypto.AES.decrypt(
          localStorage.getItem("user"),
          "elunicornio115_user"
        );
        const user = JSON.parse(decrypt.toString(Crypto.enc.Utf8));
        Object.assign(user, res.data);
        localStorage.setItem(
          "user",
          Crypto.AES.encrypt(JSON.stringify(user), "elunicornio115_user")
        );
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const generateTokenRecover = (email) => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .post(`${HOST_API_USER}/recover`, {
        email,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const changePassword = (password, token) => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .patch(`${HOST_API_USER}/recover/${token}`, {
        password,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const activateAccount = (cypher) => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .patch(`${HOST_API_USER}/activate`, {
        cypher,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export {
  myProfile,
  editProfile,
  generateTokenRecover,
  changePassword,
  getMyBillingData,
  activateAccount,
  createBillingData,
  updateBillingData,
};
