
































import { Component, Vue } from "vue-property-decorator";
import { getProductFamilies } from "@/services/productServices";
import ProductCategoryCard from "@/components/Product/ProductCategoryCard.vue";

@Component({
  components: {
    ProductCategoryCard,
  },
})
export default class CategoriesView extends Vue {
  productFamilies: any[] = [];
  page = 1;
  pageNumbers = 0;
  numberOfRender = 18;
  mounted() {
    this.obtainProductsFamilies();
  }
  obtainProductsFamilies() {
    getProductFamilies(this.numberOfRender, this.page).then(
      (res: RequestAPI) => {
        this.productFamilies = res.data;
        this.pageNumbers = res.pagination.page_total;
      }
    );
  }
}
