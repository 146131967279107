const defaultAddressForm = () => {
  return {
    full_name: "",
    address: "",
    suburb: "",
    external_number: "",
    internal_number: "",
    zip_code: "",
    country: "",
    city: "",
    additional_instructions: "",
    phone_number: "",
    user_id: null,
    address_id: null,
    email: "",
  };
};

const state = () => ({
  address: [],
  unicornAddress: {
    address_id: 4,
    internal_number: "",
    country: "Quintana Roo",
    city: "Playa del Carmen",
    external_number: "115",
    full_name: "Sucursal Unicornio 115",
    address: "Avenida 115 Mza. 028 No. LT 7 entre 26 y 28",
    suburb: "Ejidal",
    phone_number: "984 115 5214",
    zip_code: 77710,
    email: "tiendaenlinea@materialeselunicornio.com.mx",
  },
  showAddressForm: false,
  addressForm: defaultAddressForm(),
});

const actions = {
  setAddress({ state, commit }, address) {
    commit("setAddress", address);
  },
  setShowAddressForm({ state, commit }, show) {
    commit("setShowAddressForm", show);
  },
  setAddressForm({ commit }, address) {
    commit("setAddressForm", address);
  },
  cleanAddressForm({ commit }) {
    commit("cleanAddressForm");
  },
  setCity({ commit }, city) {
    commit("setCity", city);
  },
};

const getters = {
  getDefaultAddress(state) {
    if (state.address.length > 0) {
      return state.address[0];
    }
  },
  getUnicornAddress(state) {
    return state.unicornAddress;
  },
};

const mutations = {
  setAddress(state, address) {
    state.address = address;
  },
  setShowAddressForm(state, show) {
    state.showAddressForm = show;
  },
  setAddressForm(state, address) {
    if (address.address_id) {
      state.addressForm.address_id = address.address_id;
    }
    Object.assign(state.addressForm, address);
  },
  cleanAddressForm(state) {
    Object.assign(state.addressForm, defaultAddressForm());
  },
  setCity(state, city) {
    state.addressForm.country = city.estate;
    state.addressForm.city = city.city;
  },
};

export default {
  namespace: true,
  state,
  actions,
  getters,
  mutations,
};
