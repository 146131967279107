

















































































import { Vue, Component, Ref } from "vue-property-decorator";
import { login } from "@/services/authServices";
import { getMyBillingData } from "@/services/accountServices";
import { messagesAlert } from "@/plugins/toastr";
import { validations } from "@/helpers/constants";
import { getShoppingCart } from "@/services/shoppingCarServices";

@Component
export default class Login extends Vue {
  @Ref("loginForm") readonly loginForm!: VForm;
  loadingLogin = false;
  credenciales = {
    username: null,
    password: null,
  };
  fields = validations;
  loginRequest() {
    this.loadingLogin = true;
    if (this.loginForm.validate()) {
      login(this.credenciales)
        .then((res: any) => {
          this.$store.commit("setUser", res.data);
          getShoppingCart();
          getMyBillingData().then((res: any) => {
            this.$store.commit("setBilling", res);
          });
          this.$router.push({ path: "/home" });
        })
        .catch((err) => {
          this.loadingLogin = false;
          if (err.statusCode === 401) {
            messagesAlert("error", "Error", "La contraseña es incorrecta");
          } else {
            messagesAlert("error", "Error", "Lo sentimos ha ocurrido un error");
          }
        });
    } else {
      this.loadingLogin = false;
    }
  }
}
