














import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class LoaderProductCard extends Vue {
  @Prop({ default: "productCard" }) typeElement!: string;
  get generateTypeSkeleton() {
    if (this.typeElement == "recomended") {
      return "card";
    } else {
      return "image, article, actions";
    }
  }
}
