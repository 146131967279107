import store from "@/store";
import Vue from "vue";
import moment from "moment";

const PAYMENTS_HEADER_WITH_TOKEN = {
  headers: {
    "X-ApiKey": `${process.env.VUE_APP_SAP_PAYMENTS_TOKEN || ""}`,
  },
};

const ORDERS_HEADER_WITH_TOKEN = {
  headers: {
    "X-ApiKey": `${process.env.VUE_APP_SAP_ORDERS_TOKEN || ""}`,
  },
};

const generateOrder = async (
  customer,
  productos: any[],
  noCard,
  totalToPaid
) => {
  const actualDate = moment().format("YYYY-MM-DDTHH:mm:ss");
  const newOrderBody = {
    Serie: 1,
    NoCliente: customer.client_id,
    Rfc: store.getters.getBillingUser
      ? store.getters.getBillingUser.rfc
      : "XAXX010101000",
    Nombre: `${customer.first_name} ${customer.last_name}`,
    Comentarios: "Compra E-commerce",
    NoEmpleadoVentas: 28,
    Total: totalToPaid,
    FechaContabilizacion: actualDate,
    FechaDocumento: actualDate,
    FechaVencimiento: actualDate,
    Detalle: productos.map((p) => {
      return {
        Sku: p.NoArticulo,
        Cantidad: p.quantity,
        Precio: p.Precio,
        IndicadorImpuesto: "B2",
        NoTarjeta: noCard,
      };
    }),
  };
  const errorMessage = {
    EsValido: false,
    Mensaje: "ORDER_REGISTER",
  };
  return new Promise((resolve, reject) => {
    Vue.axios
      .post(
        `${process.env.VUE_APP_SAP_URL}/pedidos/GenerarPedido`,
        newOrderBody,
        ORDERS_HEADER_WITH_TOKEN
      )
      .then((res: any) => {
        if (res.EsValido) {
          resolve(res);
        } else {
          resolve(errorMessage);
        }
      })
      .catch((err) => {
        resolve(errorMessage);
      });
  });
};

const savePaymentReference = (newPayment) => {
  const errorMessage = {
    EsValido: false,
    Mensaje: "PAYMENT_REGISTER",
  };
  return new Promise((resolve, reject) => {
    Vue.axios
      .post(
        `${process.env.VUE_APP_SAP_URL}/pagos/GenerarPago`,
        newPayment,
        PAYMENTS_HEADER_WITH_TOKEN
      )
      .then((res: any) => {
        if (res.EsValido) {
          resolve(res);
        } else {
          resolve(errorMessage);
        }
      })
      .catch((err) => {
        resolve(errorMessage);
      });
  });
};

const cancelOrder = (docNum) => {
  return new Promise((resolve, reject) => {
    Vue.axios
      .get(`${process.env.VUE_APP_SAP_URL}/pedidos/CancelarPedido`, {
        params: {
          DocNum: docNum,
        },
        ...ORDERS_HEADER_WITH_TOKEN,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export { generateOrder, savePaymentReference, cancelOrder };
