























































































































import { Component, Vue } from "vue-property-decorator";
import CategoriesCardFilter from "@/components/Filters/CategoriesCardFilter.vue";
import {
  getProductCategories,
  getProductBrand,
  getProductSubcategories,
  getFilterProduct,
  getProductFamilies,
} from "@/services/productServices";
import { mapState } from "vuex";
import SimpleLoader from "@/components/Alerts/SimpleLoader.vue";

@Component({
  computed: {
    ...mapState(["filter"]),
  },
  components: {
    CategoriesCardFilter,
    SimpleLoader,
  },
})
export default class ProductFilter extends Vue {
  family: UnicornRequest = {
    data: [],
    pagination: {
      page_number: 1,
      page_size: 5,
      page_total: 1,
      total_records: 1,
    },
  };
  categories: UnicornRequest = {
    data: [],
    pagination: {
      page_number: 1,
      page_size: 5,
      page_total: 1,
      total_records: 1,
    },
  };
  subcategories: UnicornRequest = {
    data: [],
    pagination: {
      page_number: 1,
      page_size: 5,
      page_total: 1,
      total_records: 1,
    },
  };
  brands: UnicornRequest = {
    data: [],
    pagination: {
      page_number: 1,
      page_size: 5,
      page_total: 1,
      total_records: 1,
    },
  };
  loadingFilter = false;
  mounted() {
    this.loadingFilter = true;
    this.getFamily();
    this.getCategory();
    this.getBrand();
    this.getSubcategory();
    this.$nextTick(() => {
      this.loadingFilter = false;
      this.filterProducts();
    });
  }
  getFamily(pageSize = 5) {
    getProductFamilies(pageSize, 1).then((res: any) => {
      this.family = res;
    });
  }
  getCategory(pageSize = 5) {
    getProductCategories(pageSize, 1).then((res: any) => {
      this.categories = res;
    });
  }
  getBrand(pageSize = 5) {
    getProductBrand(pageSize, 1).then((res: any) => {
      this.brands = res;
    });
  }
  getSubcategory(pageSize = 5) {
    getProductSubcategories(pageSize, 1).then((res: any) => {
      this.subcategories = res;
    });
  }
  filterProducts() {
    this.loadingFilter = true;
    this.$store.state.filter.NumeroPagina = 1;
    getFilterProduct(this.$store.state.filter)
      .then((res: any) => {
        if (res.EsValido) {
          this.$store.commit("setProductList", res.Productos.Productos);
          this.$store.commit("setTotalRegisters", res.Productos.TotalRegistros);
        }
      })
      .finally(() => {
        this.loadingFilter = false;
      });
  }
}
