








import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ProductCategoryCard extends Vue {
  @Prop({ required: true }) img!: string;
  @Prop({ required: true }) title!: string;
  @Prop({ required: true, default: null }) code!: string;
  goToProductList() {
    this.$store.commit("setCategoryForFamily", {
      value: this.code,
      title: this.title,
    });
    this.$router.push({
      name: "productListCategory",
    });
  }
}
