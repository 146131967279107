






































import { Vue, Component } from "vue-property-decorator";
import SimpleLoader from "@/components/Alerts/SimpleLoader.vue";
import { activateAccount } from "@/services/accountServices";

@Component({
  components: {
    SimpleLoader,
  },
})
export default class ActivateAccount extends Vue {
  isLoadingActivation = false;
  isValidate: any = null;
  titles = {
    success: "¡Listo, cuenta activada!",
    error: "¡Lo sentimos!, pero hubo un error",
  };
  messages = {
    success:
      "Tu cuenta ya está activada por lo que ya puedes iniciar sesión con nosotros",
    error:
      "Hubo un error al momento de activar tu usuario, verifica que el link sea el correcto o intentalo más tarde",
  };
  goToLoginPage() {
    this.$router.push({ path: "/login" });
  }
  mounted() {
    this.isLoadingActivation = true;
    if (this.$route.query.token) {
      activateAccount(this.$route.query.token)
        .then((res) => {
          this.isValidate = true;
          this.isLoadingActivation = false;
        })
        .catch((err) => {
          this.isValidate = false;
          this.isLoadingActivation = false;
        });
    } else {
      this.isValidate = false;
      this.isLoadingActivation = false;
    }
  }
}
