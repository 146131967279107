const defaultSystemState = () => {
  return {
    isMaintenanceMode: false,
    generalTermsAndConditions: "",
    generalPrivacyAdvice: "",
    campaignTermsAndConditions: "",
  };
};

// init State
const state = defaultSystemState();

const actions = {
  setSystemSettings({ commit }, data) {
    commit("setSystemSettings", data);
  },
};

const getters = {
  isMaintenanceMode(state) {
    return state.isMaintenanceMode;
  },
  getGeneralTermsAndConditions(state) {
    return state.generalTermsAndConditions;
  },
  getGeneralPrivacyAdvice(state) {
    return state.generalPrivacyAdvice;
  },
  getCampaignTermsAndConditions(state) {
    return state.campaignTermsAndConditions;
  },
};

const mutations = {
  setSystemSettings(state, data) {
    state.isMaintenanceMode = data.isMaintenanceMode;
    state.generalTermsAndConditions = data.generalTermsAndConditions;
    state.generalPrivacyAdvice = data.generalPrivacyAdvice;
    state.campaignTermsAndConditions = data.campaignTermsAndConditions;
  },
};

export default {
  namespace: true,
  state,
  actions,
  getters,
  mutations,
};
