













































import { Component, Prop, Vue } from "vue-property-decorator";
import LoaderProductCard from "@/components/Carousel/LoaderProductCard.vue";

// Product Cards
import ProductCard from "@/components/Product/ProductCard.vue";
import ProductPromCard from "@/components/Product/ProductPromCard.vue";
import ProductRecomend from "@/components/Product/ProductRecomend.vue";

@Component({
  components: {
    LoaderProductCard,
    ProductCard,
    ProductPromCard,
    ProductRecomend,
  },
})
export default class Carousel extends Vue {
  @Prop({ required: true }) type!: string;
  @Prop({ default: false }) isPagination!: boolean;
  @Prop({ default: 3 }) private itemPerView!: number;
  @Prop({ default: "#FFFFFF" }) bgColor!: string;
  @Prop() isLoading!: boolean;
  @Prop({ default: () => [] }) itemList!: any[];
  swiperRoot!: HTMLDivElement;
}
